import { gql } from '@apollo/client';

export default gql`
  {
    Logs {
        Idlog
        userlog
        filelog
        datelog
        iplog
        isViewed
        agent
        user {
            Id
            Usuario
        }
    }
  }
`;