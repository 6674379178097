import { gql } from "@apollo/client";

export default gql`
  query($id: ID!) {
    ProjectAdmin(id: $id) {
      id
      name
      state
      userId
      audios {
        ID
        AudioNombre
        AudioPeso
        AudioDuracion
        AudioGeneros_
        AudioTempo_
        AudioAnimo_
        AudioInstrumento_
        AudioIdioma_
        AudioUploader
        AudioVoz_
        AudioUrl
        isDownload
        idProject
        slugUrl
        date
        isDownload
        isConfirmed
      }
      audiosNumber
      createdAt
      updatedAt
      finishedAt
    }
  }
`;
