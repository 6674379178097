import React, {useState, useEffect} from 'react'
import List from "../../Components/List"
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import getInstrumentsByOffset from "../../GraphQL/queries/getInstrumentsByOffset";
import getNumberOfInstruments from "../../GraphQL/queries/getNumberOfInstruments"
import { generateHeaders } from "../../Services/Users"
import { Headers } from '../../constants/headers'
import { useHistory } from "react-router-dom"
import { Button, Modal, Form, Input } from 'antd';
import Loading from "../../Components/Loading"
import { UpdateInstrumentService, SaveInstrumentService } from "../../Services"
import SaveInstrument from "../../GraphQL/mutations/saveInstrument"
import UpdateInstrument from "../../GraphQL/mutations/updateInstrument"
import RemoveInstrument from "../../GraphQL/mutations/removeInstrument"
import GetInstrument from "../../GraphQL/queries/getInstrument"

const validateMessages = {
    required: '${label} is required!',
};

export default () => {
    const headers = Headers["Instrument"]
    const history = useHistory()

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
    const [instrument, setInstrument] = useState(null)
    const [mode, setMode] = useState(null)
    const [instrumentValue, setInstrumentValue] = useState("")
    const [instrumentId, setInstrumentId] = useState(null)
    const [getInstrument, { data: dataLazy }] = useLazyQuery(GetInstrument, {
        onCompleted() {
            setInstrument(dataLazy.Instrument)
            setInstrumentValue(dataLazy.Instrument.Instrumento)
        },
        fetchPolicy: "network-only"
    })
    const [createInstrument] = useMutation(SaveInstrument, {
        onCompleted(){
            refetch()
        }
    })
    const [updateInstrument] = useMutation(UpdateInstrument, {
        onCompleted() {
            refetch()
        }
    })
    const [removeInstrument] = useMutation(RemoveInstrument, {
        onCompleted() {
            refetch()
        }
    })

    const [instruments, setInstruments] = useState([])
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(1)
    const [dataLoad, setDataLoad] = useState(false)
    const { loading, error, data, refetch} = useQuery(getInstrumentsByOffset, {variables: {admin: "true", offset: parseInt(offset) -1}, fetchPolicy: "network-only"})
    const { loading: loadingNumber, error: errorNumber, data: dataNumber} = useQuery(getNumberOfInstruments, {fetchPolicy: "network-only"})

    const handleChangeInstrument = (event) => {
        setInstrumentValue(event.target.value)
    };

    const removeElement = (id) => {
        setInstrumentId(id)
        setIsModalDeleteVisible(true)
    }

    const editElement = (id) => {
        getInstrument({ variables: { IdInstrumento: id } })
        setMode(2)
    }

    const handleOk = () => {
        UpdateInstrumentService(instrument.IdInstrumento, instrumentValue, updateInstrument)
        setInstrument(null)
        setMode(null)
        setInstrumentValue("")
        setIsModalVisible(false);
    }

    const handleSave = () => {
        SaveInstrumentService(instrumentValue, createInstrument)
        setInstrument(null)
        setMode(null)
        setInstrumentValue("")
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setInstrument(null)
        setMode(null)
        setInstrumentValue("")
        setIsModalVisible(false);
    };

    const createElement = () => {
        setInstrument([])
        setMode(1)
    }

    const updateTable = (total, range) => {
        if (range[0] !== offset) {
            setOffset(range[0])
        } else {
            setDataLoad(true)
        }
    }

    const handleDeleteCancel = () => {
        setIsModalDeleteVisible(false)
    }

    const handleRemove = () => {
        removeInstrument({ variables: { ID: instrumentId } })
        setInstrumentId(null)
        setIsModalDeleteVisible(false)
    }

    const handleChange = (pagination, filters,sorter) => {
        console.log("handleChange")
    }

    const headerColumns = generateHeaders(headers, editElement, removeElement)

    useEffect(() => {
        if (instrument) {
            setIsModalVisible(true);
        }
    }, [instrument])

    useEffect(() => {
        if (!loading && !error && data) {
            setInstruments(data.InstrumentLimit)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingNumber && !errorNumber && dataNumber) {
            setTotal(dataNumber.instruments)
        }
    }, [loadingNumber, dataNumber, errorNumber])

    useEffect(() => {
        if(dataLoad && offset) {
            refetch()
        }
    }, [offset, dataLoad ,refetch])

    if (error || errorNumber) return <span>Componente de error</span>
    if ((loading || loadingNumber) && !dataLoad) return <Loading />
    

    return (
        <>
            <Button onClick={createElement} style={{marginBottom: "10px", marginLeft: "10px"}} type="primary" htmlType="submit">
                Add Instrument
            </Button>
            <List dataTable={instruments} total={total} updateTable={updateTable} loading={loading} headerColumns={headerColumns} handleChange={handleChange}/>
            <Modal title={mode === 2 ? "Edit Instrument" : "New Instrument"} visible={isModalVisible} onOk={mode === 2 ? handleOk : handleSave} onCancel={handleCancel} okText={mode === 2 ? "Edit" : "Save"}>
                {instrument && (
                    <Form name="nest-messages" validateMessages={validateMessages}>
                        <Form.Item label="Name" rules={[{ required: true }]}>
                            <Input onChange={handleChangeInstrument} defaultValue={instrumentValue} className="inputStyle"/>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
            <Modal title="Delete Instrument" visible={isModalDeleteVisible} onOk={handleRemove} onCancel={handleDeleteCancel} okText="Remove">
                <p>Are you sure you want to delete?</p>
            </Modal>
            <br></br>
            <br></br>
        </>
    )
}