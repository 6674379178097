import { Breadcrumb } from "antd";

export const urls = {
  "/": {
    title: "Home",
    key: 1,
    breadcrumbs: [
      {
        title: "Home",
      },
    ],
  },
  "/damagedSongs": {
    title: "Damaged Songs",
    key: 3,
    breadcrumbs: [
      {
        title: "Songs",
      },
    ],
  },
  "/login": {
    title: "Login",
    key: 1,
    breadcrumbs: [
      {
        title: "Login",
      },
    ],
  },
  "/themes": {
    title: "List of themes",
    key: 9,
    breadcrumbs: [
      {
        title: "Themes",
      },
      {
        title: "List",
      },
    ],
  },
  "/theme/create": {
    title: "Create theme",
    key: 9,
    breadcrumbs: [
      {
        title: "Themes",
        link: "/themes",
      },
      {
        title: "Create",
      },
    ],
  },
  "/theme/edit": {
    title: "Edit theme",
    key: 9,
    breadcrumbs: [
      {
        title: "Themes",
        link: "/themes",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/songs": {
    title: "List of songs",
    key: 3,
    breadcrumbs: [
      {
        title: "Songs",
      },
      {
        title: "List",
      },
    ],
  },
  "/song/create": {
    title: "Create song",
    key: 3,
    breadcrumbs: [
      {
        title: "Songs",
        link: "/songs",
      },
      {
        title: "Create",
      },
    ],
  },
  "/song/edit": {
    title: "Edit song",
    key: 3,
    breadcrumbs: [
      {
        title: "Songs",
        link: "/songs",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/dashboard": {
    title: "Dashboard",
    key: 1,
    breadcrumbs: [
      {
        title: "Dashboard",
      },
    ],
  },
  "/users": {
    title: "List of users",
    key: 2,
    breadcrumbs: [
      {
        title: "Users",
      },
      {
        title: "List",
      },
    ],
  },
  "/user/create": {
    title: "Create user",
    key: 2,
    breadcrumbs: [
      {
        title: "Users",
        link: "/users",
      },
      {
        title: "Create",
      },
    ],
  },
  "/user/edit": {
    title: "Edit user",
    key: 2,
    breadcrumbs: [
      {
        title: "Users",
        link: "/users",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/genres": {
    title: "List of genres",
    key: 8,
    breadcrumbs: [
      {
        title: "Genres",
      },
      {
        title: "List",
      },
    ],
  },
  "/genre/create": {
    title: "Create genre",
    key: 8,
    breadcrumbs: [
      {
        title: "Users",
        link: "/genres",
      },
      {
        title: "Create",
      },
    ],
  },
  "/genre/edit": {
    title: "Edit genre",
    key: 8,
    breadcrumbs: [
      {
        title: "Genres",
        link: "/genres",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/instruments": {
    title: "List of instruments",
    key: 10,
    breadcrumbs: [
      {
        title: "Instruments",
      },
      {
        title: "List",
      },
    ],
  },
  "/instrument/create": {
    title: "Create instrument",
    key: 10,
    breadcrumbs: [
      {
        title: "Instruments",
        link: "/instruments",
      },
      {
        title: "Create",
      },
    ],
  },
  "/instrument/edit": {
    title: "Edit instrument",
    key: 10,
    breadcrumbs: [
      {
        title: "Instruments",
        link: "/instruments",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/languages": {
    title: "List of languages",
    key: 11,
    breadcrumbs: [
      {
        title: "Languages",
        link: "languages",
      },
      {
        title: "List",
      },
    ],
  },
  "/language/create": {
    title: "Create language",
    key: 11,
    breadcrumbs: [
      {
        title: "Languages",
        link: "/languages",
      },
      {
        title: "Create",
      },
    ],
  },
  "/language/edit": {
    title: "Edit language",
    key: 11,
    breadcrumbs: [
      {
        title: "Languages",
        link: "/languages",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/producers": {
    title: "List of producers",
    key: 12,
    breadcrumbs: [
      {
        title: "Producers",
      },
      {
        title: "List",
      },
    ],
  },
  "/projects": {
    title: "List of projects",
    key: 4,
    breadcrumbs: [
      {
        title: "Projects",
      },
      {
        title: "List",
      },
    ],
  },
  "/project": {
    title: "Preview project",
    key: 4,
    breadcrumbs: [
      {
        title: "Projects",
        link: "/projects",
      },
      {
        title: "Preview",
      },
    ],
  },
  "/project/edit": {
    title: "Edit project",
    key: 4,
    breadcrumbs: [
      {
        title: "Projects",
        link: "/projects",
      },
      {
        title: "Edit",
      },
    ],
  },
  "/search": {
    title: "Search",
    key: 0,
    breadcrumbs: [
      {
        title: "Search",
      },
    ],
  },
  "/config": {
    title: "Config",
    key: 7,
    breadcrumbs: [
      {
        title: "Config",
      },
    ],
  },
};
