import gql from "graphql-tag";

export default gql`
  mutation UpdateGenre(
    $IdGenero: ID!
    $Genero: String!
  ) {
    updateGenre(
      input: {
        IdGenero: $IdGenero
        Genero: $Genero
      }
    ) {
        IdGenero
        Genero
    }
  }
`;
