import { gql } from '@apollo/client';

export default gql`
  query($offset:Int!, $admin: String){
    UsersLimit(offset: $offset, admin: $admin) {
      Id
      Usuario
      nombre
      email
      limitar
    }
  }
`;