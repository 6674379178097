import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import GetConfig from "../../GraphQL/queries/getConfig"
import GetConfigFile from "../../GraphQL/queries/getConfigFile"
import UpdateConfig from "../../GraphQL/mutations/UpdateConfig"
import { Editor } from '@tinymce/tinymce-react';
import Loading from "../../Components/Loading"
import { Button, Checkbox, Modal, Form, Typography  } from "antd"
import {authenticationService} from "../../Services/Authentication/AuthenticationService"
import axios from "axios";

import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';

const { Title } = Typography;

export default function Config () {
    const [terms, setTerms] = useState("")
    const [confirmTerms, setConfirmTerms] = useState(false)
    const [config, setConfig] = useState(null)
    const [file, setFile] = useState(null)
    const [fileAlreadySaved, setFileAlreadySaved] = useState(null)
    const [fileName, setFileName] = useState(null)
    const {loading, data, error} = useQuery(GetConfig)
    const {data: dataFile} = useQuery(GetConfigFile)
    const [ updateConfig ] = useMutation(UpdateConfig)
    const [isModalVisible, setIsModalVisible] = useState(false);


    const showModal = () => {
      setIsModalVisible(true);
    };

  
    const handleOk = () => {
      handleSubmit()
      setIsModalVisible(false);
    };
  

    const handleCancel = () => {
      setIsModalVisible(false);
    };


    const handleButton = () => {
      if (!confirmTerms) {
        handleSubmit()
      } else {
        setIsModalVisible(true)
      }
    }


    const handleEditorChange = (content) => setTerms(content)


    const handleSubmit = () => {
      updateConfig({ variables: { id: config.id, value: terms, reload: confirmTerms ? 1 : 0 } }).then(res => {
        if (confirmTerms) {
          authenticationService.logout()
          window.location.reload(false);
        }
      })


      const formData = new FormData();
      formData.append("file", file);
  
      axios.post(process.env.REACT_APP_API_URL+'api/upload_terms_and_conditions_file', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'origin':'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'multipart/form-data'
        },
      }).then( (res) => {
        console.log()
        if (res?.data?.message === 'File uploaded' && res?.data?.filename) {
          setFileAlreadySaved(res.data.filename + ' se encuentra guardado.')
          setFileName(res.data.filename)
        } else {
          setFileAlreadySaved(null)
          setFileName(null)
        }
      });
      // Redirigir al Dashboard
    }


    const handleChange = (e) => setConfirmTerms(e.target.checked)


    useEffect(() => {
      if (!loading && data && !error) {
        setConfig(data.GetTerms)
        setTerms(data.GetTerms.value)
      }
    }, [loading, data, error])


    useEffect(() => {
      if (dataFile?.GetTermsFile?.value) {
        setFileAlreadySaved(dataFile.GetTermsFile.value + ' se encuentra guardado.' )
        setFileName(dataFile.GetTermsFile.value)
      }
    }, [dataFile])


    if (!terms) {
      return <Loading />
    }

    
    return (
      <>
        <Title level={2}>Terms and conditions</Title>
        <Form>


          {/* <Editor
            initialValue={terms}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
            }}
            onEditorChange={handleEditorChange}
          /> */}

          <div style={{ backgroundColor: 'white' }}>
            <ReactQuill id="desc" theme="snow" value={terms? terms : ''} onChange={setTerms}/>
          </div>

          <div style={{ marginTop: '10px', display: 'flex' }}>
            {fileAlreadySaved && fileName? 
              <a href={process.env.REACT_APP_API_URL+fileName} target="_blank" download style={{ opacity: 1, cursor: 'pointer', marginRight: '10px', backgroundColor: 'rgb(220, 220, 220)', border: '1px gray solid', paddingBlock: '2px', paddingInline: '10px', borderRadius: '3px' }}>
                {fileAlreadySaved}
              </a>
              :
              <div style={{ opacity: 0.5, cursor:'not-allowed', marginRight: '10px', backgroundColor: 'rgb(220, 220, 220)', border: '1px gray solid', paddingBlock: '2px', paddingInline: '10px', borderRadius: '3px' }}>
                No se ha encontrado ningún archivo subido.
              </div>
            }
            <input id="file" type='file' onChange={ (event) => { setFile(event.target.files[0]) }}/>
          </div>

          <Checkbox style={{ padding: "10px" }} onChange={handleChange}>Volver a aceptar los terminos y condiciones</Checkbox>
          <br />

          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button type="primary" onClick={handleButton}>Guardar</Button>
          </Form.Item>

          {isModalVisible && (
            <Modal title="Change Terms and conditions" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              <p>Esta acción cerrará la sesión de todos los usuarios (incluida la actual) y hará que tengan que volver a aceptar los nuevos términos y condiciones, seguro que quieres continuar?</p>
            </Modal>
          )}
        </Form>
      </>
    )
}
