import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../../helpers/HandleResponse";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(usuario, password, setError) {
  const requestOptions = {
    method: 'POST',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'origin':'x-requested-with',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ usuario, password }),
  };
  
  /* return fetch(`http://localhost:5001/api/signin/admin`, requestOptions) */
  return fetch(process.env.REACT_APP_API_URL+`api/signin/admin`, requestOptions).then(handleResponse).then((user) => {
      localStorage.setItem("currentUser", JSON.stringify(user.user));
      var win = window.open(
        /* `localhost:5001/redirectauth/${encodeURI(JSON.stringify(user.user))}`, */
        process.env.REACT_APP_API_URL+`redirectauth/${encodeURI(JSON.stringify(user.user))}`,
        "_blank"
      );
      /* win.focus(); */
      win.close();
      currentUserSubject.next(user.user);

      return user;
    })
    .catch((err) => {
      setError(true);
    });
}

function logout() {
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
