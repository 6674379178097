import React, {useState, useEffect} from 'react'
import List from "../../Components/List"
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import getLanguagesByOffset from "../../GraphQL/queries/getLanguagesByOffset";
import getNumberOfLanguages from "../../GraphQL/queries/getNumberOfLanguages.js"
import { generateHeaders } from "../../Services/Users"
import { Headers } from '../../constants/headers'
import { useHistory } from "react-router-dom"
import { Button, Modal, Form, Input } from 'antd';
import Loading from "../../Components/Loading"
import { UpdateLanguageService, SaveLanguageService } from "../../Services"
import UpdateIdioma from "../../GraphQL/mutations/updateIdioma"
import GetIdioma from "../../GraphQL/queries/getIdioma"
import SaveLanguage from "../../GraphQL/mutations/saveLanguage"
import RemoveLanguage from "../../GraphQL/mutations/removeLanguage"

const validateMessages = {
    required: '${label} is required!',
};

export default () => {
    const headers = Headers["Language"]
    const history = useHistory()
    const [languages, setLanguages] = useState([])
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(1)
    const [dataLoad, setDataLoad] = useState(false)
    const { loading, error, data, refetch} = useQuery(getLanguagesByOffset, {variables: { admin: "true", offset: parseInt(offset) -1}, fetchPolicy: "network-only"})
    const { loading: loadingNumber, error: errorNumber, data: dataNumber} = useQuery(getNumberOfLanguages, {fetchPolicy: "network-only"})

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
    const [language, setLanguage] = useState(null)
    const [mode, setMode] = useState(null)
    const [languageValue, setLanguageValue] = useState("")
    const [languageId, setLanguageId] = useState(null)
    const [getLanguage, { data: dataLazy }] = useLazyQuery(GetIdioma, {
        onCompleted() {
            setLanguage(dataLazy.Language)
            setLanguageValue(dataLazy.Language.Idioma)
        },
        fetchPolicy: "network-only"
    })
    const [createLanguage] = useMutation(SaveLanguage, {
        onCompleted(){
            refetch()
        }
    })
    const [updateLanguage] = useMutation(UpdateIdioma, {
        onCompleted() {
            refetch()
        }
    })
    const [removelanguage] = useMutation(RemoveLanguage, {
        onCompleted() {
            refetch()
        }
    })

    const handleChangeLanguage = (event) => {
        setLanguageValue(event.target.value)
    };

    const removeElement = (id) => {
        setLanguageId(id)
        setIsModalDeleteVisible(true)
    }

    const editElement = (id) => {
        getLanguage({ variables: { id: id } })
        setMode(2)
    }
    const createElement = () => {
        setLanguage([])
        setMode(1)
    }

    const updateTable = (total, range) => {
        if (range[0] !== offset) {
            console.log(range)
            // alert(range[0])
            setOffset(range[1])
        } else {
            setDataLoad(true)
        }
    }

    const handleChange = (pagination, filters,sorter) => {
        console.log("handleChange")
    }

    const handleOk = () => {
        UpdateLanguageService(language.IdIdioma, languageValue, updateLanguage)
        setLanguage(null)
        setMode(null)
        setLanguage("")
        setIsModalVisible(false);
    }

    const handleSave = () => {
        SaveLanguageService(languageValue, createLanguage)
        setLanguage(null)
        setMode(null)
        setLanguageValue("")
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setLanguage(null)
        setMode(null)
        setLanguageValue("")
        setIsModalVisible(false);
    };

    const handleDeleteCancel = () => {
        setIsModalDeleteVisible(false)
    }

    const handleRemove = () => {
        removelanguage({ variables: { ID: languageId } })
        setLanguageId(null)
        setIsModalDeleteVisible(false)
    }

    useEffect(() => {
        if (language) {
            setIsModalVisible(true);
        }
    }, [language])

    const headerColumns = generateHeaders(headers, editElement, removeElement)

    useEffect(() => {
        if (!loading && !error && data) {
            setLanguages(data.LanguageLimit)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingNumber && !errorNumber && dataNumber) {
            setTotal(dataNumber.languages)
        }
    }, [loadingNumber, dataNumber, errorNumber])

    useEffect(() => {
        if(dataLoad && offset) {
            refetch()
        }
    }, [offset, dataLoad ,refetch])

    if (error || errorNumber) return <span>Componente de error</span>
    if ((loading || loadingNumber) && !dataLoad) return <Loading />
    

    return (
        <>
            <Button onClick={createElement} style={{marginBottom: "10px", marginLeft: "10px"}} type="primary" htmlType="submit">
                Add Language
            </Button>
            <List dataTable={languages} total={total} updateTable={updateTable} loading={loading} headerColumns={headerColumns} handleChange={handleChange}/>
            <Modal title={mode === 2 ? "Edit Language" : "New Language"} visible={isModalVisible} onOk={mode === 2 ? handleOk : handleSave} onCancel={handleCancel} okText={mode === 2 ? "Edit" : "Save"}>
                {language && (
                    <Form name="nest-messages" validateMessages={validateMessages}>
                        <Form.Item label="Name" rules={[{ required: true }]}>
                            <Input onChange={handleChangeLanguage} defaultValue={languageValue} className="inputStyle"/>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
            <Modal title="Delete Language" visible={isModalDeleteVisible} onOk={handleRemove} onCancel={handleDeleteCancel} okText="Remove">
                <p>Are you sure you want to delete?</p>
            </Modal>
            <br></br>
            <br></br>
        </>
    )
}