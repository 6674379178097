import gql from "graphql-tag";

export default gql`
  mutation UpdateAnimo(
    $IdAnimo: ID!
    $Animo: String!
  ) {
    updateAnimo(
      input: {
        IdAnimo: $IdAnimo
        Animo: $Animo
      }
    ) {
        IdAnimo
        Animo
    }
  }
`;
