import gql from "graphql-tag";

export default gql`
  mutation UpdateSong(
    $ID: ID!
    $AudioNombre: String
    $AudioGeneros_: String
    $AudioTempo_: String
    $AudioAnimo_: String
    $AudioInstrumento_: String
    $AudioIdioma_: String
    $AudioISRC: String
    $AudioCompositor: String
    $AudioPublisher_: String
    $AudioProductor_: String
    $AudioISWC: String
    $AudioInterprete: String
    $AudioVoz_: String
  ) {
    updateAudio(
      input: {
        ID: $ID
        AudioNombre: $AudioNombre
        AudioGeneros_: $AudioGeneros_
        AudioTempo_: $AudioTempo_
        AudioAnimo_: $AudioAnimo_
        AudioInstrumento_: $AudioInstrumento_
        AudioIdioma_: $AudioIdioma_
        AudioISRC: $AudioISRC
        AudioCompositor: $AudioCompositor
        AudioPublisher_: $AudioPublisher_
        AudioProductor_: $AudioProductor_
        AudioISWC: $AudioISWC
        AudioInterprete: $AudioInterprete
        AudioVoz_: $AudioVoz_
      }
    ) {
        ID
        AudioNombre
        AudioPeso
        AudioDuracion
        AudioGeneros_
        AudioTempo_
        AudioUrl
        AudioAnimo_
        AudioInstrumento_
        AudioIdioma_
        AudioUploader
        AudioISRC
        AudioCompositor
        AudioPublisher_
        AudioProductor_
        AudioISWC
        AudioInterprete
        AudioVoz_
    }
  }
`;
