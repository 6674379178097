import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import { useMutation, useQuery } from "@apollo/client";
import ProjectInfo from "../../GraphQL/queries/getProjectInfo";
import { Form, Input, Button, Select } from "antd";
import UpdateProject from "../../GraphQL/mutations/updateProject";
import { UpdateProjectService } from "../../Services";
const { Option } = Select;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

export default () => {
  const { id } = useParams();
  const history = useHistory();
  const [project, setProject] = useState(null);
  const { loading, data, error } = useQuery(ProjectInfo, {
    variables: { id: id },
    onCompleted() {
      setProject(data.ProjectAdmin);
    },
  });
  const [updateProject, { error: errorMutation }] = useMutation(UpdateProject, {
    onCompleted() {
      history.push({
        pathname: "/projects",
      });
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const updateElement = (data) => {
    UpdateProjectService(id, data.project, updateProject);
  };
  const transformDate = (timestamp) => {
    const a = new Date(parseInt(timestamp));
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    return date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  };

  if ((error, errorMutation)) {
    return <span>Componente de error</span>;
  }
  if (project) {
    const ProjectValues = {
      name: project.name,
      state: project.state,
    };
    return (
      <div className="container_forms">
        <Form
          {...layout}
          name="nest-messages"
          onFinish={updateElement}
          initialValues={{ project: ProjectValues }}
        >
          <div>
            <Form.Item
              name={["project", "name"]}
              label="Name"
              rules={[{ required: true }]}
            >
              <Input className="inputStyle" />
            </Form.Item>
            <Form.Item name={["project", "state"]} label="State">
              <Select>
                <Option value={0}>In Progress</Option>
                <Option value={1}>Finished</Option>
                <Option value={2}>Cancelled</Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Created at">
              <span>{transformDate(project.createdAt)}</span>
            </Form.Item>
            <Form.Item label="Updated at">
              <span>
                {project.updatedAt ? transformDate(project.updatedAt) : "--"}
              </span>
            </Form.Item>
            <Form.Item label="finished at">
              <span>
                {project.finishedAt ? transformDate(project.finishedAt) : "--"}
              </span>
            </Form.Item>
          </div>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
            <Button type="primary" htmlType="submit">
              Update project
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
  return <Loading />;
};
