import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { authenticationService } from './AuthenticationService';

const TestConnection = gql`
  query testConnection {
    testConnection
  }
`;

const PrivateRoute = ({ noadmin, component: Component, ...rest }) => {
    const { error } = useQuery(TestConnection, { 
        onError() {
            authenticationService.logout()
        }
    })
    return (
        <Route {...rest} render={props => {
            const currentUser = authenticationService.currentUserValue;
            if (!currentUser) {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            } else {
                if (currentUser.admin === 2 && !noadmin) {
                    return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
                }
            }
    
            return (
                // <Layout>
                    <Component {...props} />
                // </Layout>
            )
        }} />
    )
}

export default PrivateRoute;