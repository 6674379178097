import React, {useState, useEffect} from 'react';
import { Table } from 'antd';
import { FaTools,FaTrash } from "react-icons/fa";
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom"
import RemoveAudio from "../../GraphQL/mutations/removeAudio"
import { useMutation } from '@apollo/client';
import axios from 'axios';

export default () => {
    // get songs list from /dmgdSongs
    const [dataSource, setDataSource] = useState([]);
    const [
      removeAudio,
      { loading: loadingMutation, data: dataMutation, error: errorMutation },
    ] = useMutation(RemoveAudio);
    const history = useHistory();
    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'AudioNombre',
        key: 'AudioNombre',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
      },
    ];
    
    const handleEdit = (id) => {
      history.push({
          pathname: `/song/edit/${id}`,
          state: { st: true }
      })
    }

    const handleDelete = (id) => {
      removeAudio({
        variables: {
          ID: id,
        },
      })
      .then(res => {
        window.location.reload();
      })
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + 'api/getDamagedSongs')
            .then(res => res.json())
            .then(data => {
                const dataWithActions = data.map(song => {
                    return {
                        ...song,
                        actions: <>
                          <Button style={{ marginRight: '5px' }} variant='contained' color="primary" onClick={() => handleEdit(song.ID)}><FaTools /></Button>
                          <Button variant='contained' color="secondary" onClick={() => handleDelete(song.ID)}><FaTrash /></Button>
                        </>
                    }
                });
                setDataSource(dataWithActions.filter(s => !s.isModified));
            });
    }
    , []);

      return <Table dataSource={dataSource} columns={columns} />;
}