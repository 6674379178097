import gql from "graphql-tag";

export default gql`
  mutation NewUser(
    $Usuario: String!
    $Pass: String!
    $admin: Int!
    $nombre: String!
    $email: String!
    $limitar: String!
    $descmax: Int!
  ) {
    createUser(
      input: {
        Usuario: $Usuario
        Pass: $Pass
        admin: $admin
        nombre: $nombre
        email: $email
        limitar: $limitar
        descmax: $descmax
      }
    ) {
      Id
      Usuario
      admin 
      nombre
      email
      limitar
      descmax

    }
  }
`;
