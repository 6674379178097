import { gql } from '@apollo/client';

export default gql`
  {
    LastsSongs {
        ID
        AudioNombre
        AudioPeso
        AudioDuracion
        AudioGeneros_
        AudioTempo_
        AudioAnimo_
        AudioInstrumento_
        AudioIdioma_
        AudioUploader
        AudioISRC
        AudioCompositor
        AudioPublisher_
        AudioProductor_
        AudioISWC
        AudioInterprete
        AudioVoz_
        slugUrl
    }
  }
`;