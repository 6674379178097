import { gql } from "@apollo/client";

export default gql`
  query ($offset: Int!, $admin: String) {
    ProjectLimit(offset: $offset, admin: $admin) {
      id
      name
      state
      userId
      createdAt
      updatedAt
      finishedAt
      audiosNumber
      isPlayList
      slug
      user {
        Usuario
      }
    }
  }
`;
