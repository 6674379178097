export const Headers = {
  User: [
    {
      title: "Id",
      dataIndex: "Id",
      type: "number",
    },
    {
      title: "Name",
      dataIndex: "nombre",
      type: "text",
    },
    {
      title: "User",
      dataIndex: "Usuario",
      type: "text",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      type: "text",
    },
    {
      title: "Producers",
      dataIndex: "limitar",
      type: "array",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "Id",
    },
  ],
  Audio: [
    {
      title: "Audio",
      dataIndex: "AudioNombre",
      type: "name",
    },
    /* {
      title: "Compositor",
      dataIndex: "AudioCompositor",
      type: "text",
    }, */
    {
      title: "Genres",
      dataIndex: "AudioGeneros_",
      type: "array",
      isFilterable: true,
      filterGroup: "Genres",
    },
    {
      title: "Productor",
      dataIndex: "AudioProductor_",
      type: "array",
      isFilterable: true,
      filterGroup: "Producers",
    },
    {
      title: "Tempo",
      dataIndex: "AudioTempo_",
      type: "array",
      isFilterable: true,
      filterGroup: "Tempos",
    },
    {
      title: "Theme",
      dataIndex: "AudioAnimo_",
      type: "array",
      isFilterable: true,
      filterGroup: "Mods",
    },
    {
      title: "Instruments",
      dataIndex: "AudioInstrumento_",
      type: "array",
      isFilterable: true,
      filterGroup: "Instruments",
    },
    {
      title: "Language",
      dataIndex: "AudioIdioma_",
      type: "array",
      isFilterable: true,
      filterGroup: "Languages",
    },
    {
      title: "Voice",
      dataIndex: "AudioVoz_",
      type: "array",
      isFilterable: true,
      filterGroup: "Voices",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "ID",
    },
  ],
  AudioDmg: [
    {
      title: "Audio",
      dataIndex: "AudioNombre",
      type: "name",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "ID",
    },
  ],
  Genre: [
    {
      title: "Id",
      dataIndex: "IdGenero",
      type: "number",
    },
    {
      title: "Genre",
      dataIndex: "Genero",
      type: "text",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "IdGenero",
    },
  ],
  Mod: [
    {
      title: "Id",
      dataIndex: "IdAnimo",
      type: "number",
    },
    {
      title: "Theme",
      dataIndex: "Animo",
      type: "text",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "IdAnimo",
    },
  ],
  Instrument: [
    {
      title: "Id",
      dataIndex: "IdInstrumento",
      type: "number",
    },
    {
      title: "Instrument",
      dataIndex: "Instrumento",
      type: "text",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "IdInstrumento",
    },
  ],
  Language: [
    {
      title: "Id",
      dataIndex: "IdIdioma",
      type: "number",
    },
    {
      title: "Language",
      dataIndex: "Idioma",
      type: "text",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "IdIdioma",
    },
  ],
  Projects: [
    {
      title: "Id",
      dataIndex: "id",
      type: "number",
    },
    {
      title: "User",
      dataIndex: "user",
      type: "user",
    },
    {
      title: "Name",
      dataIndex: "name",
      type: "text",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      type: "date",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      type: "date",
    },
    {
      title: "Finished at",
      dataIndex: "finishedAt",
      type: "date",
    },
    {
      title: "Songs",
      dataIndex: "audiosNumber",
      type: "number",
    },
    {
      title: "State",
      dataIndex: "state",
      type: "number",
    },
    {
      title: "Actions",
      dataIndex: "id",
      type: "editarProject",
    },
  ],
  AudioProject: [
    {
      title: "Audio",
      dataIndex: "AudioNombre",
      type: "name",
    },
    {
      title: "Added at",
      dataIndex: "AudioNombre",
      type: "addedAt",
    },
    {
      title: "is Download",
      dataIndex: "AudioNombre",
      type: "isDownload",
    },
    {
      title: "is Confirmed",
      dataIndex: "AudioNombre",
      type: "isConfirmed",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editarProject",
      pkey: "ID",
    },
  ],
  Logins: [
    {
      title: "Info",
      dataIndex: "datelog",
      type: "date",
    },
    {
      title: "Date",
      dataIndex: "datelog",
      type: "date_",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      type: "agent",
    },
    {
      title: "Action",
      dataIndex: "editar",
      type: "viewed",
    },
  ],
  LoginsList: [
    {
      title: "Info",
      dataIndex: "datelog",
      type: "date",
    },
    {
      title: "Date",
      dataIndex: "datelog",
      type: "date_",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      type: "agent",
    },
  ],
  ProjectsDashboard: [
    {
      title: "Id",
      dataIndex: "id",
      type: "number",
    },
    {
      title: "Name",
      dataIndex: "name",
      type: "text",
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      type: "date",
    },
    {
      title: "User",
      dataIndex: "user",
      type: "user",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "ver_project",
      pkey: "ID",
    },
  ],
  ProjectsFinishedDashboard: [
    {
      title: "Id",
      dataIndex: "id",
      type: "number",
    },
    {
      title: "Name",
      dataIndex: "name",
      type: "text",
    },
    {
      title: "Finished at",
      dataIndex: "finishedAt",
      type: "date",
    },
    {
      title: "User",
      dataIndex: "user",
      type: "user",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "ver_project",
      pkey: "ID",
    },
  ],
  Producer: [
    {
      title: "Id",
      dataIndex: "IdProductor",
      type: "number",
    },
    {
      title: "Producer",
      dataIndex: "Productor",
      type: "text",
    },
    {
      title: "Actions",
      dataIndex: "editar",
      type: "editar",
      pkey: "IdProductor",
    },
  ],
};
