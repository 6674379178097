import React, {useState, useEffect} from 'react'
import { Table, Typography, Collapse } from "antd"
import "./styles.css"
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Headers } from '../../constants/headers'
import { generateHeadersDashboard } from "../../Services"
import GetLogs from "../../GraphQL/queries/getLastLogins"
import GetLastsProjects from "../../GraphQL/queries/getLastProjects"
import GetProjectsFinisheds from "../../GraphQL/queries/getProjectsFinisheds"
import GetLogsList from "../../GraphQL/queries/getLogs"
import Loading from "../../Components/Loading"
import { generateHeaders } from "../../Services/Users"
import ViewedLog from "../../GraphQL/mutations/removeLog"
import CleanLogs from "../../GraphQL/mutations/cleanLogs"
import { UnorderedListOutlined, DeleteOutlined } from '@ant-design/icons';
import { Tooltip, Modal, Button, Spin, Space } from 'antd';
import { useHistory } from "react-router-dom"

const { Panel } = Collapse;

export default () => {
    const history = useHistory()
    const headersUsers = Headers['Logins']
    const headersLogsList = Headers['LoginsList']
    const headersProjects = Headers['ProjectsDashboard']
    const headersProjectsFinished = Headers['ProjectsFinishedDashboard']
    const headersDamagedSongs = Headers['AudioDmg']
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalListVisible, setIsModalListVisible] = useState(false);
    const [logs, setLogs] = useState(null)
    const [lastsProjects, setLastsProjects] = useState(null)
    const [damagedSongs, setDamagedSongs] = useState(null)
    const [lastsProjectsFinished, setLastsProjectsFinished] = useState(null)
    const [logList, setLogList] = useState(null)
    const {error, loading, data, refetch} = useQuery(GetLogs, {
        fetchPolicy: "network-only"
    })
    const [viewedLog] = useMutation(ViewedLog, {
        onCompleted() {
            refetch()
        }
    })
    const {error: errorProjects, data: dataProjects} = useQuery(GetLastsProjects, {
        onCompleted() {
            setLastsProjects(dataProjects.LastProjects ? dataProjects.LastProjects : [])
        }
    })

    const {error: errorProjectsFinished, data: dataProjectsFinished} = useQuery(GetProjectsFinisheds, {
        onCompleted() {
            setLastsProjectsFinished(dataProjectsFinished.LastProjectsFinisheds ? dataProjectsFinished.LastProjectsFinisheds : [])
        }
    })
    const [cleanLogs] = useMutation(CleanLogs, {
        onCompleted() {
            setLogs([])
        }
    })
    const [getLogs, { data: dataLazy, errorLazy }] = useLazyQuery(GetLogsList, {
        onCompleted(){
            setLogList(!errorLazy ? dataLazy.getLogs : [])
        }
    })

    

    const changePanel = (e) => {
        const x = document.querySelectorAll(".ant-collapse-item-active");  
        if(x.length){
            for(var i=0; i<x.length; i++) {
                var el = document.querySelector(".ant-collapse-item-active");     
                if (el) {
                    el.children[0].click();  
                }
            }
        }
    }

    const previewProject = (v, r) => {
        const win = window.open(`/project/${r.id}`, '_blank');
        win.focus();
    }

    const goToUser = (user) => {
        console.log(user)
        const win = window.open(`/user/edit/${user.Id}`, '_blank');
        win.focus();
    }

    const handleView = (id) => {
        viewedLog({variables: { Id: id }})
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        cleanLogs()
        setIsModalVisible(false);
    };

    const handleOkList = () => {
        setIsModalListVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const goToList = () => {
        if (logList && logList.length) {
        } else {
            getLogs()
        }
        setIsModalListVisible(true);
    }

    const cleanLogsHeader = () => {
        return (
            <>
                <Tooltip title="List">
                    <Button onClick={goToList}>
                        <UnorderedListOutlined />
                        Display all logs
                    </Button>
                </Tooltip>&nbsp;
                <Tooltip title="Clean">
                    <Button style={{ color: logs.length ? "rgba(0, 0, 0, 0.85)" : "#a8a8a8" }} onClick={ logs.length ? showModal : null}>
                        <DeleteOutlined />
                        Mark all as viewed
                    </Button>
                </Tooltip>
            </>
        )
    }

    const goToProjects = () => {
        history.push('/projects');
    }

    const goToDmgdSongs = () => {
        history.push('/damagedSongs');
    }

    const listProjects = () => {
        return (
            <Tooltip title="List">
                <Button onClick={goToProjects}>
                    <UnorderedListOutlined />
                    Display all projects
                </Button>
            </Tooltip>
        )
    }

    const buttonDmgdSongs = () => {
        return (
            <Tooltip title="List">
                <Button onClick={goToDmgdSongs}>
                    <UnorderedListOutlined />
                    Display damaged songs
                </Button>
            </Tooltip>
        )
    }

    useEffect(() => {
        if (!loading && data) {
            setLogs(data.Logs ? data.Logs : [])
        }
    }, [loading, data])

    if (logs && lastsProjects && lastsProjectsFinished) {
        const headerColumnsLogs = generateHeadersDashboard(headersUsers, goToUser, handleView)
        const headerColumnsLogsList = generateHeadersDashboard(headersLogsList, goToUser, handleView)
        const headerColumnsProjects = generateHeaders(headersProjects, previewProject)
        const headerColumnsProjectsFinished = generateHeaders(headersProjectsFinished, previewProject)
        const headerDamagedSongs = generateHeaders(headersDamagedSongs, () => null)
        return (
            <>
                <div className="dashboard">
                    <div style={{width: '49%'}} >
                        <Collapse id="" style={{marginBottom: '10px'}} onChange={changePanel} >
                            <Panel header='Projects in progress' key="0" extra={listProjects()}>
                                <div style={{ height: "400px", overflowY: "scroll" }}>
                                    <Table style={{ marginBottom: '20px' }} pagination={false} dataSource={lastsProjects} columns={headerColumnsProjects} />
                                </div>
                            </Panel>
                        </Collapse>
                        <Collapse onChange={changePanel} >
                            <Panel header='Finished projects' key="1" extra={listProjects()}>
                                <div style={{ height: "400px", overflowY: "scroll" }}>
                                    <Table pagination={false} dataSource={lastsProjectsFinished} columns={headerColumnsProjectsFinished}  />
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                    <div style={{width: '49%'}}>
                        <Collapse onChange={changePanel} style={{marginBottom: '10px'}} accordion>
                            <Panel header='Last logins' key="2" extra={cleanLogsHeader()}>
                                <div style={{ height: "400px", overflowY: "scroll" }}>
                                    <Table pagination={false} dataSource={logs} columns={headerColumnsLogs} />
                                </div>
                            </Panel>
                        </Collapse>
                        <Collapse id="" style={{marginBottom: '10px'}} onChange={changePanel}>
                            <Panel header='Cançons danyades' key="4" extra={buttonDmgdSongs()} />
                        </Collapse>
                    </div>
                    <Modal title="Lasts Logs" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <p>Mark all Logs as viewed?</p>
                    </Modal>
                </div>
                <Modal width={1000} title="Logs list" visible={isModalListVisible} onOk={handleOkList} onCancel={handleOkList}>
                    { logList ? (
                        <>
                            <Table pagination={false} dataSource={logList} columns={headerColumnsLogsList} />
                        </>
                    ) : (
                        <Space className="load" size="middle">
                            <Spin size="large" />
                        </Space>
                    ) }
                </Modal>
            </>
        )
    }

    if (error && errorProjects && errorProjectsFinished) {
        return <span>Componente de error</span>
    }

    return <Loading />
}