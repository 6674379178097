import gql from "graphql-tag";

export default gql`
  mutation UpdateProject(
    $id: ID!
    $name: String
    $state: Int!
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        state: $state
      }
    ) {
        id
        name
        createdAt
        updatedAt
        finishedAt
        state
    }
  }
`;
