import gql from "graphql-tag";

export default gql`
  mutation NewInstrument(
    $Instrumento: String!
  ) {
    createInstrument(
      input: {
        Instrumento: $Instrumento
      }
    ) {
      IdInstrumento
      Instrumento
    }
  }
`;
