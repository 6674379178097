import { gql } from "@apollo/client";

export default gql`
  query ($offset: Int!, $admin: String) {
    ProducerLimit(offset: $offset, admin: $admin) {
      IdProductor
      Productor
    }
  }
`;
