import React, {useState, useEffect} from 'react'
import { Form, Input, InputNumber, Button, Select, Row, Col } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import getProducers from "../../GraphQL/queries/getProducers"
import SaveUser from "../../GraphQL/mutations/saveUser"
import { SaveUserService } from "../../Services/Users"
import { useHistory } from "react-router-dom"

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
};

const { Option } = Select;

export default () => {
    const { loading, error, data} = useQuery(getProducers)
    const [createUser, {loading: loadingMutation, data: newUser}] = useMutation(SaveUser)
    const [producers, setProducers] = useState([])
    const [file, setFile] = useState("");
    const [invalidFile, setInvalidFile] = useState(false);
    const history = useHistory()
    const createUser_ = (user) => {
        SaveUserService(user.user, createUser, file)
    }
    const onFileChange = (e) => {
        let file = e.target.files[0].name.split(".");
        if (file[file.length - 1] !== "pdf") {
          setInvalidFile(true);
        } else {
          setInvalidFile(false);
        setFile(e.target.files[0]);
        }
    };

    useEffect(() => {
        if (!loading && !error && data) {
            const tmpProducers = []
            data.Producers.forEach(producer => {
                tmpProducers.push(
                    <Option key={producer.Productor}>{producer.Productor}</Option>
                )
            })
            setProducers(tmpProducers)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingMutation && newUser) {
            history.push({
                pathname: '/users'
            })
        }
    }, [loadingMutation, newUser])

    return (
        <Form {...layout} name="nest-messages" onFinish={createUser_} validateMessages={validateMessages}>
            <Row>
                <Col span={12} style={{ padding: "40px" }}>
                    <Form.Item name={['user', 'nombre']} label="Name" rules={[{ required: true }]}>
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <Form.Item name={['user', 'email']} label="Email" rules={[{required: true, type: 'email' }]}>
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <br />
                    <Form.Item label="Username" name={['user', 'Usuario']} rules={[{ required: true, message: 'Please input your username!' }]} >
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <Form.Item label=" New password" name={['user', 'Pass']} >
                        <Input.Password className="inputStyle" />
                    </Form.Item>
                    <br />
                    <Form.Item label="Limit downloads" name={['user', 'descmax']} rules={[{ type: 'number', min: 0, max: 1000000 }]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item name={['user', 'admin']} label="Rol" >
                        <Select style={{ width: 120 }}>
                            <Option value="0">Basic</Option>
                            <Option value="2">Uploader</Option>
                            <Option value="1">Admin</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item  label="Producers">
                        <Form.Item name={['user', 'limitar']} noStyle>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="All"
                            >
                                {producers}
                            </Select>
                        </Form.Item>
                        <small>This user will only be able to see the audios of the following producers</small>
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item style={{ margin: "0 auto" }}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={12} style={{ padding: "40px" }}>
                    <Form.Item  label="Contract">
                        {invalidFile && (
                            <small style={{ color: "red" }}>Select a valid pdf file</small>
                        )}
                    </Form.Item>
                    <Form.Item name={['user', 'document']} >
                            <input
                                style={{marginLeft: "11%"}}
                                accept=".pdf"
                                type="file"
                                onChange={onFileChange}
                            />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}