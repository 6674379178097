import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import List from "../../Components/List";
import { useQuery, useMutation } from "@apollo/client";
import getSongsByOffset from "../../GraphQL/queries/getSongsByOffset";
import getNumberOfSongs from "../../GraphQL/queries/getNumberOfSongs";
import RemoveAudio from "../../GraphQL/mutations/removeAudio";
import Genres from "../../GraphQL/queries/getGenres";
import Mods from "../../GraphQL/queries/getMods";
import Instruments from "../../GraphQL/queries/getInstruments";
import Languages from "../../GraphQL/queries/getLanguage";
import Tempos from "../../GraphQL/queries/getTempos";
import Voices from "../../GraphQL/queries/getVoices";
import Producers from "../../GraphQL/queries/getProducers";
import { generateHeaders } from "../../Services/Songs";
import { Headers } from "../../constants/headers";
import { FilterContext } from "./../../Contexts/FilterContext";
import { useHistory } from "react-router-dom";
import "./styles.css";
import Loading from "../../Components/Loading";

export default (props) => {
  const headers = Headers["Audio"];
  const { filtersAudio, setFiltersAudio, search } = useContext(FilterContext);
  // const [search, setSearch] = useState(props.location.state ? props.location.state : search_)
  const history = useHistory();
  const [filters, setFilters] = useState({
    instruments: "",
    languages: "",
    voices: "",
    tempos: "",
    genres: "",
  });
  const [songs, setSongs] = useState([]);
  const [genres, setGenres] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mods, setMods] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [tempos, setTempos] = useState([]);
  const [producers, setProducers] = useState([]);
  const [temporalId, settemporalId] = useState(null);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(1);
  const [dataLoad, setDataLoad] = useState(false);
  const { loading, error, data, refetch } = useQuery(getSongsByOffset, {
    variables: {
      offset: parseInt(offset) - 1,
      themes: filters.themes ? JSON.stringify(filters.themes) : "",
      instruments: filters.instruments
        ? JSON.stringify(filters.instruments)
        : "",
      languages: filters.languages ? JSON.stringify(filters.languages) : "",
      voices: filters.voices ? JSON.stringify(filters.voices) : "",
      tempos: filters.tempos ? JSON.stringify(filters.tempos) : "",
      genres: filters.genres ? JSON.stringify(filters.genres) : "",
      search: search,
      admin: "true",
    },
    fetchPolicy: "network-only",
  });
  const [
    removeAudio,
    { loading: loadingMutation, data: dataMutation, error: errorMutation },
  ] = useMutation(RemoveAudio);
  const {
    loading: loadingNumber,
    error: errorNumber,
    data: dataNumber,
    refetch: refetchNumber,
  } = useQuery(getNumberOfSongs, {
    variables: {
      offset: parseInt(offset) - 1,
      themes: filters.themes ? JSON.stringify(filters.themes) : "",
      instruments: filters.instruments
        ? JSON.stringify(filters.instruments)
        : "",
      languages: filters.languages ? JSON.stringify(filters.languages) : "",
      voices: filters.voices ? JSON.stringify(filters.voices) : "",
      tempos: filters.tempos ? JSON.stringify(filters.tempos) : "",
      genres: filters.genres ? JSON.stringify(filters.genres) : "",
      search: search,
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingGenres,
    error: errorGenres,
    data: dataGenres,
  } = useQuery(Genres);
  const {
    loading: loadingMods,
    error: errorMods,
    data: dataMods,
  } = useQuery(Mods);
  const {
    loading: loadingInstruments,
    error: errorInstruments,
    data: dataInstruments,
  } = useQuery(Instruments);
  const {
    loading: loadingLanguages,
    error: errorLanguages,
    data: dataLanguages,
  } = useQuery(Languages);
  const {
    loading: loadingTempos,
    error: errorTempos,
    data: dataTempos,
  } = useQuery(Tempos);
  const {
    loading: loadingVoices,
    error: errorVoices,
    data: dataVoices,
  } = useQuery(Voices);
  const {
    loading: loadingProducers,
    error: errorProducers,
    data: dataProducers,
  } = useQuery(Producers);

  const deleteElementFromList = (id) => {
    setSongs(songs.filter((song) => song.ID !== id));
  };

  const removeElement = (id) => {
    removeAudio({
      variables: {
        ID: temporalId,
      },
    });
    deleteElementFromList(temporalId);
  };

  const editElement = (id) => {
    history.push({
      pathname: `/song/edit/${id}`,
    });
  };

  const updateTable = (total, range) => {
    if (range[0] !== offset) {
      setOffset(range[0]);
    } else {
      setDataLoad(true);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilters({
      instruments: filters.AudioInstrumento_ ? filters.AudioInstrumento_ : "",
      themes: filters.AudioAnimo_ ? filters.AudioAnimo_ : "",
      languages: filters.AudioIdioma_ ? filters.AudioIdioma_ : "",
      voices: filters.AudioVoz_ ? filters.AudioVoz_ : "",
      tempos: filters.AudioTempo_ ? filters.AudioTempo_ : "",
      genres: filters.AudioGeneros_ ? filters.AudioGeneros_ : "",
    });
    if (offset == 1) {
      refetch();
    } else {
      setOffset(1);
    }
  };
  const tmpDelete = (id) => {
    settemporalId(id);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    removeElement();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const headerColumns = generateHeaders(
    headers,
    editElement,
    tmpDelete,
    genres,
    mods,
    instruments,
    languages,
    tempos,
    voices,
    producers,
    setFiltersAudio,
    filtersAudio,
    showModal
  );


  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [offset]);





  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      refetch();
    }
  }, [loadingMutation, dataMutation]);

  useEffect(() => {
    if (!loading && !error && data) {
      setSongs(data.AudiosFilter);
      refetchNumber();
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (!loadingNumber && !errorNumber && dataNumber) {
      setTotal(dataNumber.AudiosFilterNumber);
    }
  }, [loadingNumber, dataNumber, errorNumber]);

  useEffect(() => {
    if (!loadingGenres && dataGenres && !errorGenres) {
      setGenres(dataGenres.Genres);
    }
  }, [loadingGenres, dataGenres, errorGenres]);

  useEffect(() => {
    if (!loadingMods && dataMods && !errorMods) {
      setMods(dataMods.Animos);
    }
  }, [loadingMods, dataMods, errorMods]);

  useEffect(() => {
    if (!loadingInstruments && dataInstruments && !errorInstruments) {
      setInstruments(dataInstruments.Instruments);
    }
  }, [loadingInstruments, dataInstruments, errorInstruments]);

  useEffect(() => {
    if (!loadingLanguages && dataLanguages && !errorLanguages) {
      setLanguages(dataLanguages.Languages);
    }
  }, [loadingLanguages, dataLanguages, errorLanguages]);

  useEffect(() => {
    if (!loadingTempos && dataTempos && !errorTempos) {
      setTempos(dataTempos.Tempos);
    }
  }, [loadingTempos, dataTempos, errorTempos]);

  useEffect(() => {
    if (!loadingVoices && dataVoices && !errorVoices) {
      setVoices(dataVoices.Voices);
    }
  }, [loadingVoices, dataVoices, errorVoices]);

  useEffect(() => {
    if (!loadingProducers && dataProducers && !errorProducers) {
      setProducers(dataProducers.Producers);
    }
  }, [loadingProducers, dataProducers, errorProducers]);

  useEffect(() => {
    if (dataLoad && offset) {
      refetch();
    }
  }, [offset, dataLoad, refetch, search]);

  if (error || errorNumber) return <span>Componente de error</span>;
  if ((loading || loadingNumber) && !dataLoad) return <Loading />;

  return (
    <>
      <h3>Se han encontrado {total} resultados.</h3>
      {console.log(songs)}
      <List
        dataTable={songs}
        total={total}
        updateTable={updateTable}
        loading={loading}
        headerColumns={headerColumns}
        handleChange={handleChange}
      />
      <Modal
        title="Delete Song"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure to delete this song?</p>
      </Modal>


      
      <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
        <button type="button" style={{ opacity: offset>1? 1 : 0.5 }} onClick={() => setOffset(offset - 1) } disabled={offset>1? false : true}>Previous page</button>
        <button type="button" style={{ marginLeft: '10px', opacity: offset<(total/80)? 1 : 0.5 }} onClick={() => setOffset(offset + 1) } disabled={offset<(total/80)? false : true}>Next page</button>
      </div>
      <br></br><br></br><br></br>
    </>
  );
};
