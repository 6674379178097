import gql from "graphql-tag";

export default gql`
    mutation($ID: ID!){
        removeGenre(IdGenero: $ID) {
            IdGenero
            Genero
    }
  }
`;
