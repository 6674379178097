import React from "react";
import { SiMozillafirefox, SiInternetexplorer, SiLinux } from "react-icons/si";
import { FaSafari } from "react-icons/fa";
import {
  AndroidFilled,
  AppleFilled,
  ChromeFilled,
  TabletFilled,
  MobileFilled,
  WindowsFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

export const SaveProducerService = (data, save) => {
  save({
    variables: {
      Productor: data,
    },
  });
};

export const SaveGenreService = (data, save) => {
  save({
    variables: {
      Genero: data,
    },
  });
};
export const SaveModService = (data, save) => {
  save({
    variables: {
      Animo: data,
    },
  });
};
export const SaveLanguageService = (data, save) => {
  save({
    variables: {
      Idioma: data,
    },
  });
};
export const SaveInstrumentService = (data, save) => {
  save({
    variables: {
      Instrumento: data,
    },
  });
};

export const UpdateGenreService = (id, data, save) => {
  save({
    variables: {
      IdGenero: id,
      Genero: data,
    },
  });
};

export const UpdateProducerService = (id, data, save) => {
  save({
    variables: {
      IdProductor: id,
      Productor: data,
    },
  });
};

export const UpdateInstrumentService = (id, data, save) => {
  save({
    variables: {
      IdInstrumento: id,
      Instrumento: data,
    },
  });
};

export const UpdateAnimoService = (id, data, save) => {
  save({
    variables: {
      IdAnimo: id,
      Animo: data,
    },
  });
};

export const UpdateLanguageService = (id, data, save) => {
  save({
    variables: {
      IdIdioma: id,
      Idioma: data,
    },
  });
};

export const UpdateProjectService = (id, data, update) => {
  update({
    variables: {
      id: id,
      name: data.name,
      state: parseInt(data.state),
    },
  });
};

export const generateHeadersDashboard = (header, goToUser, handleView) => {
  const headers_ = header;
  headers_.forEach((head, index) => {
    if (head.type === "date_") {
      headers_[index].render = (text, row, index) => {
        let time = "";
        let time_ = "";
        if (text) {
          const a = new Date(parseInt(text));
          var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          var year = a.getFullYear();
          var month = months[a.getMonth()];
          var num_month = a.getMonth() + 1;
          var date = a.getDate();
          var hour = a.getHours();
          var min =
            parseInt(a.getMinutes()) > 10
              ? a.getMinutes()
              : "0" + a.getMinutes();
          var sec =
            parseInt(a.getSeconds()) > 10
              ? a.getSeconds()
              : "0" + a.getSeconds();
          time_ =
            date +
            " " +
            month +
            " " +
            year +
            " " +
            hour +
            ":" +
            min +
            ":" +
            sec;
          time =
            (date < 10 ? "0" + date : date) +
            "/" +
            (num_month < 10 ? "0" + num_month : num_month) +
            "/" +
            year.toString().substr(-2) +
            " " +
            hour +
            ":" +
            min +
            ":" +
            sec;
        }
        return <>{text ? <Tooltip title={time_}>{time}</Tooltip> : "---"}</>;
      };
    }
    if (head.type === "date") {
      headers_[index].render = (text, row, index) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => goToUser(row.user)}
            >
              <b>{row.user.Usuario}</b>
            </span>{" "}
            &nbsp;
            <small>
              ({row.iplog.split(":")[row.iplog.split(":").length - 1]})
            </small>
          </>
        );
      };
    }
    if (head.type === "agent") {
      headers_[index].render = (text, row, index) => {
        let response = [];
        if (text.indexOf("Android") !== -1) {
          response.push(<AndroidFilled style={{ margin: "3px" }} />);
        } else if (text.indexOf("Iphone") !== -1) {
          response.push(<MobileFilled style={{ margin: "3px" }} />);
        } else if (text.indexOf("Ipad") !== -1) {
          response.push(<TabletFilled style={{ margin: "3px" }} />);
        }
        if (text.indexOf("Mac OS") !== -1) {
          response.push(<AppleFilled style={{ margin: "3px" }} />);
        } else if (text.indexOf("Windows") !== -1) {
          response.push(<WindowsFilled style={{ margin: "3px" }} />);
        } else if (text.indexOf("Linux") !== -1) {
          response.push(<SiLinux style={{ marginLeft: "3px" }} />);
        }

        if (text.indexOf("Firefox") !== -1) {
          response.push(<SiMozillafirefox style={{ marginLeft: "3px" }} />);
        } else if (text.indexOf("MSIE") !== -1) {
          response.push(<SiInternetexplorer style={{ margin: "3px" }} />);
        } else if (text.indexOf("Chrome") !== -1) {
          response.push(<ChromeFilled style={{ margin: "3px" }} />);
        } else if (text.indexOf("Safari") !== -1) {
          response.push(<FaSafari style={{ margin: "3px" }} />);
        }
        return <Tooltip title={text}>{response}</Tooltip>;
      };
    }
    if (head.type === "viewed") {
      headers_[index].render = (text, row, index) => {
        return (
          <Tooltip title="Hidde log">
            <span
              onClick={() => handleView(row.Idlog)}
              style={{ cursor: "pointer" }}
            >
              <EyeOutlined />
            </span>
          </Tooltip>
        );
      };
    }
  });
  return headers_;
};
