import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Client } from "./Configs/ApolloClient";
import { FilterContextProvider } from "./Contexts/FilterContext";
import Layout from "./Components/Layout/LayoutContext";

import SignIn from "./Pages/Login/SignIn";
import Dashboard from "./Pages/Dashboard";
import Users from "./Pages/Users";
import UserEdit from "./Pages/Users/edit";
import UserCreate from "./Pages/Users/new";
import Songs from "./Pages/Songs";
import SongEdit from "./Pages/Songs/edit";
import SongCreate from "./Pages/Songs/new";
import DmgdSongs from "./Pages/Songs/dmgd";
import SongSearch from "./Pages/Songs/search";
import Genres from "./Pages/Genres";
import Producers from "./Pages/Producers";
import Mods from "./Pages/Mods";
import Instruments from "./Pages/Instruments";
import Languages from "./Pages/Languages";
import Projects from "./Pages/Projects";
import ProjectInfo from "./Pages/Projects/ProjectInfo";
import ProjectEdit from "./Pages/Projects/edit";
import ConfigPage from "./Pages/ConfigPage";
import PrivateRoute from "./Services/Authentication/AuthHeader";
import "./index.css";

function App() {
  return (
    <ApolloProvider client={Client}>
      <FilterContextProvider>
        <Router>
          <Layout>
            <Route exact path="/login" component={SignIn} />
            <PrivateRoute noadmin={true} exact path="/" component={Dashboard} />
            {/* ruta de dmgs songs */}
            <PrivateRoute
            exact 
            path="/damagedSongs"
            component={DmgdSongs}
            />

            <PrivateRoute
              noadmin={true}
              exact
              path="/dashboard"
              component={Dashboard}
            />
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/user/edit/:uid" component={UserEdit} />
            <PrivateRoute exact path="/user/create" component={UserCreate} />
            <PrivateRoute
              noadmin={true}
              exact
              path="/songs"
              component={Songs}
            />
            <PrivateRoute
              noadmin={true}
              exact
              path="/song/edit/:uid"
              component={SongEdit}
            />
            <PrivateRoute
              noadmin={true}
              exact
              path="/song/create"
              component={SongCreate}
            />
            <PrivateRoute
              noadmin={true}
              exact
              path="/search"
              component={SongSearch}
            />
            <PrivateRoute exact path="/genres" component={Genres} />
            <PrivateRoute exact path="/producers" component={Producers} />
            <PrivateRoute exact path="/themes" component={Mods} />
            <PrivateRoute exact path="/instruments" component={Instruments} />
            <PrivateRoute exact path="/languages" component={Languages} />
            <PrivateRoute exact path="/projects" component={Projects} />
            <PrivateRoute exact path="/project/:id" component={ProjectInfo} />
            <PrivateRoute
              exact
              path="/project/edit/:id"
              component={ProjectEdit}
            />
            <PrivateRoute exact path="/config" component={ConfigPage} />
          </Layout>
        </Router>
      </FilterContextProvider>
    </ApolloProvider>
  );
}

export default App;
