import React, {useState, useEffect} from 'react'
import { Form, Input, InputNumber, Button, Select, Row, Col } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import getProducers from "../../GraphQL/queries/getProducers"
import GetUser from "../../GraphQL/queries/getUser"
import { useHistory, useParams } from "react-router-dom"
import UpdateUser from "../../GraphQL/mutations/updateUser"
import { UpdateUserService } from "../../Services/Users"
import Loading from "../../Components/Loading"
import { FilePdfOutlined } from '@ant-design/icons'
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
};

const { Option } = Select;

export default () => {
    const {uid} = useParams()
    const { loading, error, data} = useQuery(getProducers)
    const [file, setFile] = useState("");
    const [invalidFile, setInvalidFile] = useState(false);
    const { loading: loadingUser, error: errorUser, data: dataUser} = useQuery(GetUser, {variables: {Id: uid}})
    const [updateUser, {loading: loadingMutation, data: updatedUser}] = useMutation(UpdateUser)
    const [user, setUser] = useState(null)
    const [producers, setProducers] = useState([])

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const history = useHistory()
    const updateUser_ = (userForm) => {
        UpdateUserService(uid, userForm.user, updateUser, file)
    }

    const onFileChange = (e) => {
        let file = e.target.files[0].name.split(".");
        if (file[file.length - 1] !== "pdf") {
          setInvalidFile(true);
        } else {
          setInvalidFile(false);
        setFile(e.target.files[0]);
        }
    };

    const openDocument = () => {
        /* window.open(`localhost:3001/documents/${user.documento}`, "_blank") */
        window.open(process.env.REACT_APP_API_URL+`documents/${user.documento}`, "_blank")
    }

    useEffect(() => {
        if (!loading && !error && data) {
            const tmpProducers = []
            data.Producers.forEach(producer => {
                tmpProducers.push(
                    <Option key={producer.Productor}>{producer.Productor}</Option>
                )
            })
            setProducers(tmpProducers)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingUser && !errorUser && dataUser) {
            setUser(dataUser.UserInfo)
        }
    }, [loadingUser, errorUser, dataUser])

    useEffect(() => {
        if (!loadingMutation && updatedUser) {
            history.push({
                pathname: '/users'
            })
        }
    }, [loadingMutation, updatedUser])

    if (!user) return <Loading />
    if (errorUser) return <span>Error al obtener el usuario</span>

    const userValues = {
        nombre: user.nombre,
        Usuario: user.Usuario,
        email: user.email,
        descmax: user.descmax,
        limitar: user.limitar === "all" ? [] : user.limitar.split(", "),
        admin: String(user.admin)
    }

    const cambio = () => {
        setTimeout(() => {
            let me = document.getElementsByClassName("react-pdf__Page__canvas")
            me[0].style.width = "60%"
            me[0].style.height = "15%"
        }, 100);
    }

    return (
        <Form {...layout} name="nest-messages" onFinish={updateUser_} validateMessages={validateMessages} initialValues={{user: userValues}}>
            <Row>
                <Col span={12} style={{ padding: "40px" }}>
                    <Form.Item name={['user', 'nombre']} label="Name" rules={[{ required: true }]}>
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <Form.Item name={['user', 'email']} label="Email" rules={[{required: true, type: 'email' }]}>
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <br />
                    <Form.Item label="Username" name={['user', 'Usuario']} rules={[{ disabled: true, required: true, message: 'Please input your username!' }]} >
                        <Input className="inputStyle"/>
                    </Form.Item>
                    <Form.Item label=" New password" name={['user', 'Pass']} >
                        <Input.Password className="inputStyle" />
                    </Form.Item>
                    <br />
                    <Form.Item label="Limit downloads" name={['user', 'descmax']} rules={[{ type: 'number', min: 0, max: 1000000 }]}>
                        <InputNumber/>
                    </Form.Item>
                    <Form.Item name={['user', 'admin']} label="Rol" >
                        <Select style={{ width: 120 }}>
                            <Option value="0">Basic</Option>
                            <Option value="2">Uploader</Option>
                            <Option value="1">Admin</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item  label="Producers">
                        <Form.Item name={['user', 'limitar']} noStyle>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="All"
                            >
                                {producers}
                            </Select>
                        </Form.Item>
                        <small>This user will only be able to see the audios of the following producers</small>
                    </Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item style={{ margin: "0 auto" }}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={12} style={{ padding: "40px" }}>
                    <Form.Item  label="Contract">
                        {user.documento && 
                            <div style={{ padding: '10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={openDocument}>
                                <FilePdfOutlined />&nbsp;
                                <span>View in a new tab</span>
                            </div>
                        }
                        <div>
                            <Document
                                style={{ width: "30px" }}
                                /* file={`localhost:3001/documents/${user.documento}`} */
                                file={process.env.REACT_APP_API_URL+`documents/${user.documento}`}
                                onLoadSuccess={cambio}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                        </div>
                        {invalidFile && (
                            <small style={{ color: "red" }}>Select a valid pdf file</small>
                        )}
                    </Form.Item>
                    <Form.Item name={['user', 'document']} >
                            <input
                                style={{marginLeft: "11%"}}
                                accept=".pdf"
                                type="file"
                                onChange={onFileChange}
                            />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}