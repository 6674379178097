import React, { useState, useEffect } from "react";
import List from "../../Components/List";
import { useQuery, useMutation } from "@apollo/client";
import getProjectsByOffset from "../../GraphQL/queries/getProjectsByOffset";
import getNumberOfProjects from "../../GraphQL/queries/getNumberOfProjects";
import RemoveProject from "../../GraphQL/mutations/removeProject";
import PlaylistProject from "../../GraphQL/mutations/playlistProject";
import { generateHeaders } from "../../Services/Users";
import { Headers } from "../../constants/headers";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "antd";
import Loading from "../../Components/Loading";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default () => {
  const headers = Headers["Projects"];
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [visiblePlaylist, setVisiblePlaylist] = useState(false);
  const [loadingPlayList, setLoadingPlayList] = useState(false);
  const [offset, setOffset] = useState(1);
  const [dataLoad, setDataLoad] = useState(false);
  const { loading, error, data, refetch } = useQuery(getProjectsByOffset, {
    variables: { admin: "true", offset: parseInt(offset) - 1 },
  });
  const [playlistProject] = useMutation(PlaylistProject, {
    onCompleted(data) {
      setLoadingPlayList(false);
      setProject(data.projectToPlaylist);
    },
  });
  const [removeProject] = useMutation(RemoveProject, {
    onCompleted() {
      refetch();
    },
  });
  const {
    loading: loadingNumber,
    error: errorNumber,
    data: dataNumber,
  } = useQuery(getNumberOfProjects);
  const [idProject, setIdProject] = useState(null);

  const removeElement = (id) => {};

  const editElement = (id) => {
    history.push({
      pathname: `/project/${id}`,
    });
  };

  const updateTable = (total, range) => {
    if (range[0] !== offset) {
      setOffset(range[0]);
    } else {
      setDataLoad(true);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("handleChange");
  };

  const editProject = (id) => {
    history.push({
      pathname: `/project/edit/${id}`,
    });
  };
  const showModal = (idproject) => {
    setIdProject(idproject);
    setVisible(true);
  };

  const showModalPlaylist = (idproject) => {
    setIdProject(idproject);
    setVisiblePlaylist(true);
  };

  const handleOk = (e) => {
    remove();
    setVisible(false);
  };

  const handleOkPlaylist = (e) => {
    setVisiblePlaylist(false);
    // algo
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleCancelPlaylist = (e) => {
    setVisiblePlaylist(false);
  };

  const remove = () => {
    removeProject({
      variables: {
        idProject: parseInt(idProject),
      },
    });
  };

  const setPlayList = (pr) => {
    setProject(pr);
    setVisiblePlaylist(true);
  };

  const playlistproject_ = () => {
    setLoadingPlayList(true);
    playlistProject({
      variables: {
        idProject: parseInt(project.id),
      },
    });
  };

  const headerColumns = generateHeaders(
    headers,
    editElement,
    removeElement,
    editProject,
    showModal,
    setPlayList
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setProjects(data.ProjectLimit);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (!loadingNumber && !errorNumber && dataNumber) {
      setTotal(dataNumber.projects);
    }
  }, [loadingNumber, dataNumber, errorNumber]);

  useEffect(() => {
    if (dataLoad && offset) {
      refetch();
    }
  }, [offset, dataLoad, refetch]);

  if (error || errorNumber) return <span>Componente de error</span>;
  if ((loading || loadingNumber) && !dataLoad) return <Loading />;

  return (
    <>
      <List
        dataTable={projects}
        total={total}
        updateTable={updateTable}
        loading={loading}
        headerColumns={headerColumns}
        handleChange={handleChange}
      />
      <Modal
        title="Delete project"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to remove the project?</p>
      </Modal>
      <Modal
        title="Project to Playlist"
        visible={visiblePlaylist}
        onCancel={handleCancelPlaylist}
        footer={[
          <Button key="cancel" onClick={handleCancelPlaylist}>
            Cancel
          </Button>,
          ,
        ]}
      >
        {loadingPlayList ? (
          <span>Cargando</span>
        ) : project ? (
          <>
            {!project.isPlayList ? (
              <div className="container_modal--playlist">
                <Button
                  onClick={playlistproject_}
                  style={{ marginBottom: "10px" }}
                  type="primary"
                >
                  Activar Playlist
                </Button>
              </div>
            ) : (
              <div className="container_modal--playlist">
                <Button
                  onClick={playlistproject_}
                  style={{ marginBottom: "10px" }}
                  type="primary"
                >
                  Desactivar Playlist
                </Button>
                <div className="link_playlist">
                  {process.env.REACT_APP_URL+`playlist/${project.slug}`}
                  <CopyToClipboard
                    text={process.env.REACT_APP_URL+`playlist/${project.slug}`}
                  >
                    <CopyOutlined
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    />
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </>
        ) : (
          <span>
            Ha ocurrido un error inesperado, contacte con el administrador.
          </span>
        )}
      </Modal>
    </>
  );
};
