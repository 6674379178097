import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";




const httpLink = createHttpLink({
  /* uri: 'http://localhost:5001/graphql', */
  uri: process.env.REACT_APP_API_URL+'graphql',
  credentials: 'same-origin'
})

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem('currentUser'))
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.token}` : ""
    }
  }
})

export const Client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
