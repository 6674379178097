import gql from "graphql-tag";

export default gql`
    mutation($ID: ID!){
        removeInstrument(IdInstrumento: $ID) {
            IdInstrumento
            Instrumento
    }
  }
`;
