import gql from "graphql-tag";

export default gql`
  mutation ($ID: ID!) {
    removeProducer(IdProductor: $ID) {
      IdProductor
      Productor
    }
  }
`;
