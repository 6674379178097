import { gql } from "@apollo/client";

export default gql`
  query(
    $genres: String
    $themes: String
    $offset: Int!
    $instruments: String
    $tempos: String
    $voice: String
    $languages: String
    $admin: String
    $search: String
    $producers: String
    $iswc: Int
    $isrc: Int
    $dateFilter: String
  ) {
    AudiosFilter(
      input: {
        genres: $genres
        themes: $themes
        offset: $offset
        instruments: $instruments
        tempos: $tempos
        voices: $voice
        languages: $languages
        admin: $admin
        search: $search
        producers: $producers
        iswc: $iswc
        isrc: $isrc
        dateFilter: $dateFilter
      }
    ) {
      ID
      AudioNombre
      AudioPeso
      AudioDuracion
      AudioGeneros_
      AudioTempo_
      AudioAnimo_
      AudioInstrumento_
      AudioIdioma_
      AudioProductor_
      AudioUploader
      AudioVoz_
      AudioUrl
      slugUrl
      AudioSubido
    }
  }
`;
