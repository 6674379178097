import React, {useState, useEffect} from 'react'
import List from "../../Components/List"
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import getModsByOffset from "../../GraphQL/queries/getModsByOffset";
import getNumberOfMods from "../../GraphQL/queries/getNumberOfMods"
import { generateHeaders } from "../../Services/Users"
import { Headers } from '../../constants/headers'
import { useHistory } from "react-router-dom"
import { Button, Modal, Form, Input } from 'antd';
import Loading from "../../Components/Loading"
import GetAnimo from "../../GraphQL/queries/getAnimo"
import UpdateAnimo from "../../GraphQL/mutations/updateAnimo"
import RemoveTheme from "../../GraphQL/mutations/removeTheme"
import SaveMod from "../../GraphQL/mutations/saveMod"
import { UpdateAnimoService, SaveModService } from "../../Services"

const validateMessages = {
    required: '${label} is required!',
};

export default () => {
    const headers = Headers["Mod"]
    const history = useHistory()
    const [mods, setMods] = useState([])
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(1)
    const [dataLoad, setDataLoad] = useState(false)
    const { loading, error, data, refetch} = useQuery(getModsByOffset, {variables: {admin: "true", offset: parseInt(offset) -1}})
    const { loading: loadingNumber, error: errorNumber, data: dataNumber} = useQuery(getNumberOfMods)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
    const [theme, setTheme] = useState(null)
    const [mode, setMode] = useState(null)
    const [themeValue, setThemeValue] = useState("")
    const [themeId, setThemeId] = useState(null)
    const [getTheme, { data: dataLazy }] = useLazyQuery(GetAnimo, {
        onCompleted() {
            setTheme(dataLazy.Animo)
            setThemeValue(dataLazy.Animo.Animo)
        },
        fetchPolicy: "network-only"
    })
    const [createTheme] = useMutation(SaveMod, {
        onCompleted(){
            refetch()
        }
    })
    const [updateAnimo] = useMutation(UpdateAnimo, {
        onCompleted(){
            refetch()
        }
    })
    const [removeAnimo] = useMutation(RemoveTheme, {
        onCompleted(){
            refetch()
        }
    })

    const handleOk = () => {
        UpdateAnimoService(theme.IdAnimo, themeValue, updateAnimo)
        setTheme(null)
        setMode(null)
        setThemeValue("")
        setIsModalVisible(false);
    };

    const handleSave = () => {
        SaveModService(themeValue, createTheme)
        setTheme(null)
        setMode(null)
        setThemeValue("")
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setTheme(null)
        setMode(null)
        setThemeValue("")
        setIsModalVisible(false);
    };

    const removeElement = (id) => {
        setThemeId(id)
        setIsModalDeleteVisible(true)
    }

    const editElement = (id) => {
        getTheme({ variables: { IdAnimo: id } })
        setMode(2)
    }

    const createElement = () => {
        setTheme([])
        setMode(1)
    }

    const updateTable = (total, range) => {
        if (range[0] !== offset) {
            setOffset(range[0])
        } else {
            setDataLoad(true)
        }
    }

    const handleChange = (pagination, filters,sorter) => {
        console.log("handleChange")
    }

    const handleChangeTheme = (event) => {
        setThemeValue(event.target.value)
    };

    const handleDeleteCancel = () => {
        setIsModalDeleteVisible(false)
    }

    const handleRemove = () => {
        removeAnimo({ variables: { ID: themeId } })
        setThemeId(null)
        setIsModalDeleteVisible(false)
    }

    const headerColumns = generateHeaders(headers, editElement, removeElement)

    useEffect(() => {
        if (theme) {
            setIsModalVisible(true);
        }
    }, [theme])

    useEffect(() => {
        if (!loading && !error && data) {
            setMods(data.ModsLimit)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingNumber && !errorNumber && dataNumber) {
            setTotal(dataNumber.mods)
        }
    }, [loadingNumber, dataNumber, errorNumber])

    useEffect(() => {
        if(dataLoad && offset) {
            refetch()
        }
    }, [offset, dataLoad ,refetch])

    if (error || errorNumber) return <span>Componente de error</span>
    if ((loading || loadingNumber) && !dataLoad) return <Loading />
    

    return (
        <>
            <Button onClick={createElement} style={{marginBottom: "10px", marginLeft: "10px"}} type="primary" htmlType="submit">
                Add Theme
            </Button>
            <List dataTable={mods} total={total} updateTable={updateTable} loading={loading} headerColumns={headerColumns} handleChange={handleChange}/>
            <Modal title={mode === 2 ? "Edit Theme" : "New Theme"} visible={isModalVisible} onOk={mode === 2 ? handleOk : handleSave} onCancel={handleCancel} okText={mode === 2 ? "Edit" : "Save"}>
                {theme && (
                    <Form name="nest-messages" validateMessages={validateMessages}>
                        <Form.Item label="Name" rules={[{ required: true }]}>
                            <Input onChange={handleChangeTheme} defaultValue={themeValue} className="inputStyle"/>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
            <Modal title="Delete Theme" visible={isModalDeleteVisible} onOk={handleRemove} onCancel={handleDeleteCancel} okText="Remove">
                <p>Are you sure you want to delete?</p>
            </Modal>
            <br></br>
            <br></br>
        </>
    )
}