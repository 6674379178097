import React, { useState, useEffect } from "react";
import List from "../../Components/List";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import getProducersByOffset from "../../GraphQL/queries/getProducersByOffset";
import getNumberOfProducers from "../../GraphQL/queries/getNumberOfProducers";
import { generateHeaders } from "../../Services/Users";
import { Headers } from "../../constants/headers";
import { useHistory } from "react-router-dom";
import { Button, Modal, Form, Input } from "antd";
import Loading from "../../Components/Loading";
import GetProducer from "../../GraphQL/queries/getProducer";
import UpdateProducer from "../../GraphQL/mutations/updateProducer";
import { SaveProducerService, UpdateProducerService } from "../../Services";
import SaveProducer from "../../GraphQL/mutations/saveProducer";
import RemoveProducer from "../../GraphQL/mutations/removeProducer";

const validateMessages = {
  required: "${label} is required!",
};

export default () => {
  const headers = Headers["Producer"];
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [producer, setProducer] = useState(null);
  const [mode, setMode] = useState(null);
  const [producerValue, setProducerValue] = useState("");
  const [producerId, setProducerId] = useState(null);
  const [getProducer, { data: dataLazy }] = useLazyQuery(GetProducer, {
    onCompleted() {
      setProducer(dataLazy.Producer);
      setProducerValue(dataLazy.Producer.Productor);
    },
    fetchPolicy: "network-only",
  });
  const [createProducer] = useMutation(SaveProducer, {
    onCompleted() {
      refetch();
    },
  });
  const [producers, setProducers] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(1);
  const [dataLoad, setDataLoad] = useState(false);
  const { loading, error, data, refetch } = useQuery(getProducersByOffset, {
    variables: { admin: "true", offset: parseInt(offset) - 1 },
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingNumber,
    error: errorNumber,
    data: dataNumber,
  } = useQuery(getNumberOfProducers, { fetchPolicy: "network-only" });
  const [updateProducer] = useMutation(UpdateProducer, {
    onCompleted() {
      refetch();
    },
  });
  const [removeProducer] = useMutation(RemoveProducer, {
    onCompleted() {
      refetch();
    },
  });

  const handleChangeProducer = (event) => {
    setProducerValue(event.target.value);
  };

  const handleOk = () => {
    UpdateProducerService(producer.IdProductor, producerValue, updateProducer);
    setProducer(null);
    setMode(null);
    setProducerValue("");
    setIsModalVisible(false);
  };

  const handleSave = () => {
    SaveProducerService(producerValue, createProducer);
    setProducer(null);
    setMode(null);
    setProducerValue("");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setProducer(null);
    setMode(null);
    setProducerValue("");
    setIsModalVisible(false);
  };

  const removeElement = (id) => {
    setProducerId(id);
    setIsModalDeleteVisible(true);
  };

  const editElement = (id) => {
    getProducer({ variables: { IdProductor: id } });
    setMode(2);
  };

  useEffect(() => {
    if (producer) {
      setIsModalVisible(true);
    }
  }, [producer]);

  const createElement = () => {
    setProducer([]);
    setMode(1);
  };

  const updateTable = (total, range) => {
    if (range[0] !== offset) {
      setOffset(range[0]);
    } else {
      setDataLoad(true);
    }
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteVisible(false);
  };

  const handleRemove = () => {
    removeProducer({ variables: { ID: producerId } });
    setProducerId(null);
    setIsModalDeleteVisible(false);
  };

  const handleChange = () => {};

  const headerColumns = generateHeaders(headers, editElement, removeElement);

  useEffect(() => {
    if (!loading && !error && data) {
      setProducers(data.ProducerLimit);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (!loadingNumber && !errorNumber && dataNumber) {
      setTotal(dataNumber.producers);
    }
  }, [loadingNumber, dataNumber, errorNumber]);

  useEffect(() => {
    if (dataLoad && offset) {
      refetch();
    }
  }, [offset, dataLoad, refetch]);

  if (error || errorNumber) return <span>Componente de error</span>;
  if ((loading || loadingNumber) && !dataLoad) return <Loading />;

  return (
    <>
      <Button
        onClick={createElement}
        style={{ marginBottom: "10px", marginLeft: "10px" }}
        type="primary"
        htmlType="submit"
      >
        Add Producer
      </Button>
      <List
        dataTable={producers}
        total={total}
        updateTable={updateTable}
        loading={loading}
        headerColumns={headerColumns}
        handleChange={handleChange}
      />
      <Modal
        title={mode === 2 ? "Edit Producer" : "New Producer"}
        visible={isModalVisible}
        onOk={mode === 2 ? handleOk : handleSave}
        onCancel={handleCancel}
        okText={mode === 2 ? "Edit" : "Save"}
      >
        {producer && (
          <Form name="nest-messages" validateMessages={validateMessages}>
            <Form.Item label="Name" rules={[{ required: true }]}>
              <Input
                onChange={handleChangeProducer}
                defaultValue={producerValue}
                className="inputStyle"
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title="Delete Producer"
        visible={isModalDeleteVisible}
        onOk={handleRemove}
        onCancel={handleDeleteCancel}
        okText="Remove"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
      <br></br>
      <br></br>
    </>
  );
};
