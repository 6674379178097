import gql from "graphql-tag";

export default gql`
  mutation NewProducer($Productor: String!) {
    createProducer(input: { Productor: $Productor }) {
      IdProductor
      Productor
    }
  }
`;
