import { gql } from "@apollo/client";

export default gql`
  query GetSong($Id: ID!) {
    Audio(ID: $Id) {
      ID
      AudioNombre
      AudioPeso
      AudioDuracion
      AudioGeneros_
      AudioTempo_
      AudioAnimo_
      AudioInstrumento_
      AudioIdioma_
      AudioUploader
      AudioISRC
      AudioCompositor
      AudioPublisher_
      AudioProductor_
      AudioISWC
      AudioInterprete
      AudioVoz_
      slugUrl
    }
  }
`;
