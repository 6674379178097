import gql from "graphql-tag";

export default gql`
  mutation UpdateProducer($IdProductor: ID!, $Productor: String!) {
    updateProducer(
      input: { IdProductor: $IdProductor, Productor: $Productor }
    ) {
      IdProductor
      Productor
    }
  }
`;
