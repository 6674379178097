import { gql } from '@apollo/client';

export default gql`
  {
    getLogs {
      Idlog
      userlog
      filelog
      datelog
      iplog
      isViewed
      agent
      user {
        Id
        Usuario
      }
    }
  }
`;