import gql from "graphql-tag";

export default gql`
  mutation UpdateIdioma(
    $IdIdioma: ID!
    $Idioma: String!
  ) {
    updateLanguage(
      input: {
        IdIdioma: $IdIdioma
        Idioma: $Idioma
      }
    ) {
        IdIdioma
        Idioma
    }
  }
`;
