import gql from "graphql-tag";

export default gql`
  mutation NewGenre(
    $Genero: String!
  ) {
    createGenre(
      input: {
        Genero: $Genero
      }
    ) {
      IdGenero
      Genero
    }
  }
`;
