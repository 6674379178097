import React, {useState, useEffect} from 'react'
import List from "../../Components/List"
import { useQuery, useMutation } from '@apollo/client';
import getUsersByOffset from "../../GraphQL/queries/getUsersByOffset";
import getNumberOfUsers from "../../GraphQL/queries/getNumberOfUsers"
import { generateHeaders } from "../../Services/Users"
import { Headers } from '../../constants/headers'
import { useHistory } from "react-router-dom"
import { Button, Modal } from 'antd';
import Loading from "../../Components/Loading"
import RemoveUser from "../../GraphQL/mutations/removeUser"

export default () => {
    const headers = Headers["User"]
    const history = useHistory()
    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(1)
    const [dataLoad, setDataLoad] = useState(false)
    const { loading, error, data, refetch} = useQuery(getUsersByOffset, {variables: { admin: "true", offset: parseInt(offset) -1}})
    const { loading: loadingNumber, error: errorNumber, data: dataNumber} = useQuery(getNumberOfUsers)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [temporalId, settemporalId] = useState(null);
    const [
        removeUser,
        { loading: loadingMutation, data: dataMutation, error: errorMutation },
      ] = useMutation(RemoveUser);

    const removeElement = (id) => {
        settemporalId(id);
        setIsModalVisible(true)
    }
    const editElement = (id) => {
        history.push({
            pathname: `/user/edit/${id}`
        })
    }
    const createElement = () => {
        history.push({
            pathname: `/user/create`
        })
    }

    const updateTable = (total, range) => {
        if (range[0] !== offset) {
            setOffset(range[0])
        } else {
            setDataLoad(true)
        }
    }

    const handleChange = (pagination, filters,sorter) => {
        console.log("handleChange")
    }

    const headerColumns = generateHeaders(headers, editElement, removeElement)

    useEffect(() => {
        if (!loading && !error && data) {
            setUsers(data.UsersLimit)
        }
    }, [loading, data,error])

    useEffect(() => {
        if (!loadingNumber && !errorNumber && dataNumber) {
            setTotal(dataNumber.users)
        }
    }, [loadingNumber, dataNumber, errorNumber])

    useEffect(() => {
        if(dataLoad && offset) {
            refetch()
        }
    }, [offset, dataLoad ,refetch])

    const removeUser_ = () => {
        removeUser({ variables: { Id: temporalId} }).then(r => {
            refetch()
        })
        settemporalId(null)
    }

    const handleOk = () => {
        removeUser_();
        setIsModalVisible(false);
    };
    
    const handleCancel = () => {
        settemporalId(null)
        setIsModalVisible(false);
    };

    if (error || errorNumber) return <span>Componente de error</span>
    if ((loading || loadingNumber) && !dataLoad) return <Loading />
    
    return (
        <>
            <Button onClick={createElement} style={{marginBottom: "10px"}} type="primary" htmlType="submit">
                Add user
            </Button>
            <List dataTable={users} total={total} updateTable={updateTable} loading={loading} headerColumns={headerColumns} handleChange={handleChange}/>
            <Modal
                title="Delete User"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Are you sure to delete this user?</p>
            </Modal>
        </>
    )
}