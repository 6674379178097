import gql from "graphql-tag";

export default gql`
  mutation NewMood(
    $Animo: String!
  ) {
    createMood(
      input: {
        Animo: $Animo
      }
    ) {
      IdAnimo
      Animo
    }
  }
`;
