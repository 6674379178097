import { gql } from '@apollo/client';

export default gql`
mutation UpdateConfig($id: ID!, $value: String!, $reload: Int!) {
    updateConfig(input: {
        id: $id
        value: $value
        reload: $reload
    }) {
        id
        position
        editable
        key_
        name
        type
        value
  }
}
`;