import { gql } from '@apollo/client';

export default gql`
query GetConfigFile {
  GetTermsFile {
      id
      position
      editable
      key_
      name
      type
      value
  }
}
`;