import React from "react";
import { Tag, Space, Tooltip, Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactAudioPlayer from 'react-audio-player';
import { getInfoFromCurrentUser } from "../../helpers/utils"

export const generateFilterGenres = (data) => {
  const arr = [];
  data.forEach((el) => {
    arr.push({ text: el.Genero, value: el.IdGenero });
  });
  return arr;
};

const generateGenreFilter = (genres) => {
  const res = [];
  genres.forEach((gender) => {
    res.push({
      text: gender.Genero,
      value: gender.IdGenero,
    });
  });
  return res;
};
const generateModFilter = (mods) => {
  const res = [];
  mods.forEach((mod) => {
    res.push({
      text: mod.Animo,
      value: mod.IdAnimo,
    });
  });
  return res;
};

const generateInstrumentsFilter = (instruments) => {
  const res = [];
  instruments.forEach((instrument) => {
    res.push({
      text: instrument.Instrumento,
      value: instrument.IdInstrumento,
    });
  });
  return res;
};

const generateLanguagesFilter = (languages) => {
  const res = [];
  languages.forEach((language) => {
    res.push({
      text: language.Idioma,
      value: language.IdIdioma,
    });
  });
  return res;
};

const generateTemposFilter = (tempos) => {
  const res = [];
  tempos.forEach((tempo) => {
    res.push({
      text: tempo.Tempo,
      value: tempo.IdTempo,
    });
  });
  return res;
};

const generateVoicesFilter = (voices) => {
  const res = [];
  voices.forEach((voice) => {
    res.push({
      text: voice.Voz,
      value: voice.IdVoz,
    });
  });
  return res;
};

const generateProducersFilter = (voices) => {
  const res = [];
  voices.forEach((voice) => {
    res.push({
      text: voice.Productor,
      value: voice.IdProductor,
    });
  });
  return res;
};

export const generateHeaders = (
  headers,
  edit,
  remove,
  genres,
  mods,
  instruments,
  languages,
  tempos,
  voices,
  producers,
  setFilter,
  filter,
  showModal
) => {
  const headers_ = headers;
  headers_.forEach((head, index) => {
    if (head.type === "editar") {
      headers_[index].render = (text, row, index) => {
        return (
          <>
            <Tooltip title="edit">
              <Button
                onClick={() => edit(row[head.pkey], row)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size={"middle"}
              />
            </Tooltip>{" "}
            <Tooltip title="delete">
              <Button
                onClick={() => {
                  remove(row[head.pkey]);
                  showModal();
                }}
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size={"middle"}
              />
            </Tooltip>
          </>
        );
      };
      headers_[index].width = 105;
    }
    if (head.type === "array") {
      headers_[index].render = (text, row, index) => {
        if (text) {
          return text.split(", ").map((tag) => (
            <Tag color="blue" style={{ margin: "1px" }} key={tag + row.ID}>
              {tag}
            </Tag>
          ));
        }
      };
    }
    if (head.type === "name") {
      headers_[index].render = (text, row, index) => {
        return (
          <span>
            {row.AudioNombre}
            <br />

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <small>({row.AudioPeso}) &nbsp; <i>#{row.ID}</i></small>

              <small> { new Date(parseInt(row.AudioSubido)).toLocaleDateString("es-ES") } </small>
            </div>

            <ReactAudioPlayer
              /* src={`localhost:3001/audio/${row.slugUrl}`} */
              src={process.env.REACT_APP_API_URL+`audio/${row.slugUrl}`}
              controls
            />
          </span>
        );
      };
      headers_[index].width = 300;
    }
    if (head.type === "editarProject") {
      headers_[index].render = (text, row, index) => {
        return (
          <Space>
            <Tooltip title="delete">
              <Button
                onClick={() => remove(row.ID, row.idProject)}
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size={"middle"}
              />
            </Tooltip>
          </Space>
        );
      };
    }
    if (head.type === "addedAt") {
      headers_[index].render = (text, row, index) => {
        let time = ""
        if (row.date) {
            const a = new Date(parseInt(row.date))
            var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = parseInt(a.getMinutes()) > 10 ? a.getMinutes() : "0" + a.getMinutes();
            var sec = parseInt(a.getSeconds()) > 10 ? a.getSeconds() : "0" + a.getSeconds()
            time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        }
        return row.date ? time: "---"
      };
    }
    if (head.type === "isDownload") {
      headers_[index].render = (text, row, index) => {
        return row.isDownload ? "Yes" : "No"
      };
    }
    if (head.type === "isConfirmed") {
      headers_[index].render = (text, row, index) => {
        return row.isConfirmed ? "Yes" : "No"
      };
    }
    if (head.isFilterable && head.filterGroup === "Genres") {
      headers_[index].filters = generateGenreFilter(genres);
    }
    if (head.isFilterable && head.filterGroup === "Mods") {
      headers_[index].filters = generateModFilter(mods);
    }
    if (head.isFilterable && head.filterGroup === "Instruments") {
      headers_[index].filters = generateInstrumentsFilter(instruments);
    }
    if (head.isFilterable && head.filterGroup === "Languages") {
      headers_[index].filters = generateLanguagesFilter(languages);
    }
    if (head.isFilterable && head.filterGroup === "Tempos") {
      headers_[index].filters = generateTemposFilter(tempos);
    }
    if (head.isFilterable && head.filterGroup === "Voices") {
      headers_[index].filters = generateVoicesFilter(voices);
    }
    if (head.isFilterable && head.filterGroup === "Producers") {
      headers_[index].filters = generateProducersFilter(producers);
    }
  });
  return headers_;
};

export const SaveSongService = (data, save, audioFile, genres, themes, instruments) => {
  const username = getInfoFromCurrentUser("user")
  save({
    variables: {
      AudioNombre: data.AudioNombre,
      AudioGeneros_: genres.length ? genres.join(", ") : "",
      AudioTempo_: data.AudioTempo ? data.AudioTempo.join(", ") : "",
      AudioAnimo_: themes.length ? themes.join(", ") : "",
      AudioInstrumento_: instruments.length
        ? instruments.join(", ")
        : "",
      AudioIdioma_: data.AudioLanguage ? data.AudioLanguage.join(", ") : "",
      AudioISRC: data.AudioISRC ? data.AudioISRC : "",
      AudioCompositor: data.AudioCompositor ? data.AudioCompositor : "",
      AudioProductor_: data.AudioProducers
        ? data.AudioProducers.join(", ")
        : "",
      AudioPublisher_: data.AudioPublisher ? data.AudioPublisher : "JBC Music",
      AudioISWC: data.AudioISWC ? data.AudioISWC : "",
      AudioInterprete: data.AudioInterprete ? data.AudioInterprete : "",
      AudioVoz_: data.AudioVoz ? data.AudioVoz.join(", ") : "",
      AudioUploader: username
    },
  }).then((res) => {
    const formData1 = new FormData();
    formData1.append("audioFile", audioFile);
    formData1.append("idAudio", res.data.createAudio.ID);

    
    /* url: 'localhost:3001/api/upload_file' */
    /* const config = {
      url: process.env.REACT_APP_API_URL+'api/upload_file',
      method: 'POST',
      headers: {
          'Access-Control-Allow-Origin': '*',
          'origin':'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'application/json',
      },
      formData1
    };

    axios.post(config); */

    axios.post(process.env.REACT_APP_API_URL+'api/upload_file', formData1, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'origin':'x-requested-with',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'multipart/form-data'
      },
    });
  });
};

export const UpdateSongService = (id, data, update, audioFile, genres, themes, instruments) => {
  update({
    variables: {
      ID: id,
      AudioNombre: data.AudioNombre,
      AudioGeneros_: genres.join(", "),
      AudioTempo_: data.AudioTempo.join(", "),
      AudioAnimo_: themes.join(", "),
      AudioInstrumento_: instruments.join(", "),
      AudioIdioma_: data.AudioLanguage.join(", "),
      AudioISRC: data.AudioISRC,
      AudioCompositor: data.AudioCompositor,
      AudioProductor_: data.AudioProducers.join(", "),
      AudioPublisher_: data.AudioPublisher ? data.AudioPublisher : "JBC Music",
      AudioISWC: data.AudioISWC,
      AudioInterprete: data.AudioInterprete,
      AudioVoz_: data.AudioVoz.join(", "),
    },
  }).then((res) => {
    if (audioFile) {
      const formData2 = new FormData();
      formData2.append("audioFile", audioFile);
      formData2.append("idAudio", res.data.updateAudio.ID);

      axios.post(process.env.REACT_APP_API_URL+'api/upload_file', formData2, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'origin':'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'multipart/form-data'
        },
      });
      
      /* axios.post("localhost:3001/api/upload_file", formData, {}); */
    }
  });
};
