import React from "react";
import { Tag, Tooltip, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";

export const SaveUserService = (data, save, file) => {
  save({
    variables: {
      Usuario: data.Usuario,
      Pass: data.Pass,
      admin: data.admin ? parseInt(data.admin) : 0,
      nombre: data.nombre,
      email: data.email,
      limitar:
        data.limitar && data.limitar.length ? data.limitar.join(", ") : "all",
      descmax: data.descmax ? parseInt(data.descmax) : 0,
    },
  }).then((res) => {
    const formData = new FormData();
    formData.append("documentFile", file);
    formData.append("id", res.data.createUser.Id);


    /* url: 'localhost:5001/api/upload_document', */

    const config = {
      url: process.env.REACT_APP_API_URL+'api/upload_document',
      method: 'POST',
      headers: {
          'Access-Control-Allow-Origin': '*',
          'origin':'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'application/json',
      },
      formData
    };
    axios.post(config);
    /* axios.post("localhost:5001/api/upload_document", formData, {}); */
  });
};

export const UpdateUserService = (id, data, update, file) => {
  update({
    variables: {
      Id: id,
      Usuario: data.Usuario,
      Pass: data.Pass ? data.Pass : "",
      admin: data.admin ? parseInt(data.admin) : 0,
      nombre: data.nombre,
      email: data.email,
      limitar:
        data.limitar && data.limitar.length ? data.limitar.join(", ") : "all",
      descmax: data.descmax ? parseInt(data.descmax) : 0,
    },
  }).then((res) => {
    const formData = new FormData();
    formData.append("documentFile", file);
    formData.append("id", res.data.updateUser.Id);

    const config = {
      url: process.env.REACT_APP_API_URL+'api/update_document',
      method: 'POST',
      headers: {
          'Access-Control-Allow-Origin': '*',
          'origin':'x-requested-with',
          'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Content-Type': 'application/json',
      },
      formData
    };
    axios.post(config);
    /* axios.post("localhost:5001/api/update_document", formData, {}); */
  });
};

export const generateHeaders = (
  headers,
  edit,
  remove,
  editProject,
  showModal,
  setPlayList
) => {
  const headers_ = headers;
  headers_.forEach((head, index) => {
    if (head.type === "editar") {
      headers_[index].render = (text, row, index) => {
        return (
          <>
            <Tooltip title="edit">
              <Button
                onClick={() => edit(row[head.pkey], row)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size={"middle"}
              />
            </Tooltip>{" "}
            <Tooltip title="remove">
              <Button
                onClick={() => remove(row[head.pkey])}
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size={"middle"}
              />
            </Tooltip>
          </>
        );
      };
    }
    if (head.type === "array") {
      headers_[index].render = (text, row, index) => {
        return text.split(", ").map((tag) => (
          <Tag color="blue" key={tag}>
            {tag}
          </Tag>
        ));
      };
    }
    if (head.type === "date") {
      headers_[index].render = (text, row, index) => {
        let time = "";
        if (text) {
          const a = new Date(parseInt(text));
          var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          var year = a.getFullYear();
          var month = months[a.getMonth()];
          var date = a.getDate();
          var hour = a.getHours();
          var min =
            parseInt(a.getMinutes()) > 10
              ? a.getMinutes()
              : "0" + a.getMinutes();
          var sec =
            parseInt(a.getSeconds()) > 10
              ? a.getSeconds()
              : "0" + a.getSeconds();
          time =
            date +
            " " +
            month +
            " " +
            year +
            " " +
            hour +
            ":" +
            min +
            ":" +
            sec;
        }
        return text ? time : "---";
      };
    }
    if (head.type === "user") {
      headers_[index].render = (text, row, index) => {
        return text ? text.Usuario : "---";
      };
    }
    if (head.dataIndex === "state") {
      headers_[index].render = (text, row, index) => {
        if (text === 0) {
          return "In progress";
        }
        return "Finished";
      };
    }
    if (head.type === "editarProject") {
      headers_[index].render = (text, row, index) => {
        return (
          <div className="flex actionButtons">
            <Tooltip title="preview">
              <Button
                className="mr-4"
                onClick={() => edit(text)}
                type="ghost"
                shape="circle"
                icon={<EyeOutlined />}
                size={"middle"}
              />
            </Tooltip>
            {"   "}
            <Tooltip
              title={row.isPlayList ? "Playlist pública" : "Generar playlist"}
            >
              <Button
                className={`${
                  row.isPlayList ? "p_public" : "p_no_public"
                } mr-4`}
                onClick={() => setPlayList(row)}
                type="ghost"
                shape="circle"
                icon={<PlayCircleOutlined />}
                size={"middle"}
              />
            </Tooltip>
            {"   "}
            <Tooltip title="edit">
              <Button
                className="mr-4"
                onClick={() => editProject(text)}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size={"middle"}
              />
            </Tooltip>
            {"   "}
            <Tooltip
              title="remove"
              onClick={() => {
                showModal(text);
              }}
            >
              <Button
                className="mr-4"
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size={"middle"}
              />
            </Tooltip>
          </div>
        );
      };
    }
    if (head.type === "ver_project") {
      headers_[index].render = (text, row, index) => {
        return (
          <>
            <Tooltip title="preview">
              <Button
                onClick={() => edit(text, row)}
                type="ghost"
                shape="circle"
                icon={<EyeOutlined />}
                size={"middle"}
              />
            </Tooltip>
          </>
        );
      };
    }
  });
  return headers_;
};
