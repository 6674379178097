import gql from "graphql-tag";

export default gql`
  mutation NewLanguage(
    $Idioma: String!
  ) {
    createLanguage(
      input: {
        Idioma: $Idioma
      }
    ) {
      IdIdioma
      Idioma
    }
  }
`;
