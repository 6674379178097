import { gql } from '@apollo/client';

export default gql`
    query($genres: String, $themes: String, $offset: Int, $instruments: String, $tempos: String, $voice: String, $languages: String, $search: String, $producers: String, $iswc: Int, $isrc: Int){
        AudiosFilterNumber(input: {
            genres: $genres
            themes: $themes
            offset: $offset
            instruments: $instruments
            tempos: $tempos
            voices: $voice
            languages: $languages
            search: $search
            producers: $producers
            iswc: $iswc
            isrc: $isrc
          })
    }
`;