import React, { useState } from 'react'
import { Table, Pagination } from 'antd';
import "./styles.css"


export default ({dataTable, total, updateTable, loading, headerColumns, handleChange, setAudioPlayer}) => {


    return (
        <>
            <Table onChange={handleChange} columns={headerColumns} loading={total || !loading ? false : true} dataSource={dataTable} pagination={false} onRow={(data) => ({
                onClick: () => {
                    if (setAudioPlayer) {
                        setAudioPlayer(data.AudioUrl, data.AudioNombre)
                    }
                }
            })} />
            {/* {(total && total > 80) && ( */}
            {(false) && (
                <Pagination defaultPageSize={80} className={"pagination"} showSizeChanger={false} total={total} showTotal={(total, range) => {
                    updateTable(total, range)
                }} />
             )}
        </>
    )


}