import gql from "graphql-tag";

export default gql`
  mutation UpdateInstrument(
    $IdInstrumento: ID!
    $Instrumento: String!
  ) {
    updateInstrument(
      input: {
        IdInstrumento: $IdInstrumento
        Instrumento: $Instrumento
      }
    ) {
        IdInstrumento
        Instrumento
    }
  }
`;
