import gql from "graphql-tag";

export default gql`
    mutation($ID: ID!){
        removeAudio(ID: $ID) {
            ID
            AudioNombre
    }
  }
`;
