import gql from "graphql-tag";

export default gql`
    mutation($Id: ID!){
        removeUser(Id: $Id) {
            Id
            Usuario
    }
  }
`;
