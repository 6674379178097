import React from 'react'
import { Spin, Space } from 'antd';
import "./styles.css"

export default () => {
    return (
        <Space className="load" size="middle">
            <Spin size="large" />
        </Space>
    )
}