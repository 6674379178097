import React, { useState, createContext, useEffect } from 'react'

export const FilterContext = createContext();

export const FilterContextProvider = ({ children }) => {
    const [filtersAudio, setFiltersAudio] = useState({
        filtros: false,
        generos: [],
        animos: [],
        instrumentos: [],
        idiomas: []
    })
    const [search, setSearch] = useState("")

    return (
        <FilterContext.Provider
            value={{
                filtersAudio,
                setFiltersAudio,
                search,
                setSearch
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}