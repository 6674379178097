import { gql } from "@apollo/client";

export default gql`
  query GetProducer($IdProductor: ID!) {
    Producer(IdProductor: $IdProductor) {
      IdProductor
      Productor
    }
  }
`;
