import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Checkbox } from "antd";
import List from "../../Components/List";
import { useQuery, useMutation } from "@apollo/client";
import getSongsByOffset from "../../GraphQL/queries/getSongsByOffset";
import getNumberOfSongs from "../../GraphQL/queries/getNumberOfSongs";
import RemoveAudio from "../../GraphQL/mutations/removeAudio";
import Genres from "../../GraphQL/queries/getGenres";
import Mods from "../../GraphQL/queries/getMods";
import Instruments from "../../GraphQL/queries/getInstruments";
import Languages from "../../GraphQL/queries/getLanguage";
import Tempos from "../../GraphQL/queries/getTempos";
import Voices from "../../GraphQL/queries/getVoices";
import Producers from "../../GraphQL/queries/getProducers";
import { generateHeaders } from "../../Services/Songs";
import { Headers } from "../../constants/headers";
import { FilterContext } from "./../../Contexts/FilterContext";
import { useHistory } from "react-router-dom";
import "./styles.css";
import Loading from "../../Components/Loading";
import { SearchOutlined } from "@ant-design/icons";
import tw, { styled } from "twin.macro";
import { Select } from 'antd';

const Input = styled.input`
  ${tw`py-3 px-2 flex-1 text-xl`};
  background-color: #dcdcdc;
  color: white;
  border: 0;
  border-radius: 30px;
  &::placeholder {
    color: black;
    opacity: 0.2;
  }
  &:focus {
    outline: 0;
    color: black;
  }
`;



export default () => {

  const headers = Headers["Audio"];
  const history = useHistory();
  const [filters, setFilters] = useState({
    instruments: "",
    languages: "",
    voices: "",
    tempos: "",
    genres: "",
    producers: "",
  });
  const [iswc, setISRC] = useState(0);
  const [isrc, setISWC] = useState(0);
  const [tmpSearch, setTmpSearch] = useState("");
  const [temporalId, settemporalId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [songs, setSongs] = useState([]);
  const [genres, setGenres] = useState([]);
  const [mods, setMods] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [producers, setProducers] = useState([]);
  const [tempos, setTempos] = useState([]);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(1);
  const [dataLoad, setDataLoad] = useState(false);
  const [dateFilter, setDateFilter] = useState(null);

  const [headerstable, setHeaderstable] = useState([])


  const { filtersAudio, setFiltersAudio, search, setSearch } =
    useContext(FilterContext);
  const { loading, error, data, refetch } = useQuery(getSongsByOffset, {
    variables: {
      offset: (parseInt(offset)-1)*80,// parseInt(offset) - 1,
      themes: filters.themes ? JSON.stringify(filters.themes) : "",
      instruments: filters.instruments
        ? JSON.stringify(filters.instruments)
        : "",
      languages: filters.languages ? JSON.stringify(filters.languages) : "",
      voices: filters.voices ? JSON.stringify(filters.voices) : "",
      tempos: filters.tempos ? JSON.stringify(filters.tempos) : "",
      genres: filters.genres ? JSON.stringify(filters.genres) : "",
      producers: filters.producers ? JSON.stringify(filters.producers) : "",
      admin: "true",
      iswc: iswc,
      isrc: isrc,
      dateFilter: dateFilter,
    },
  });



  const [
    removeAudio,
    { loading: loadingMutation, data: dataMutation, error: errorMutation },
  ] = useMutation(RemoveAudio);

  const {
    loading: loadingNumber,
    error: errorNumber,
    data: dataNumber,
    refetch: refetchNumber,
  } = useQuery(getNumberOfSongs, {
    variables: {
      offset: parseInt(offset) - 1,
      themes: filters.themes ? JSON.stringify(filters.themes) : "",
      instruments: filters.instruments
        ? JSON.stringify(filters.instruments)
        : "",
      languages: filters.languages ? JSON.stringify(filters.languages) : "",
      voices: filters.voices ? JSON.stringify(filters.voices) : "",
      tempos: filters.tempos ? JSON.stringify(filters.tempos) : "",
      genres: filters.genres ? JSON.stringify(filters.genres) : "",
      producers: filters.producers ? JSON.stringify(filters.producers) : "",
      iswc: iswc,
      isrc: isrc,
    },
  });
  
  const {
    loading: loadingGenres,
    error: errorGenres,
    data: dataGenres,
  } = useQuery(Genres);
  const {
    loading: loadingMods,
    error: errorMods,
    data: dataMods,
  } = useQuery(Mods);
  const {
    loading: loadingInstruments,
    error: errorInstruments,
    data: dataInstruments,
  } = useQuery(Instruments);
  const {
    loading: loadingLanguages,
    error: errorLanguages,
    data: dataLanguages,
  } = useQuery(Languages);
  const {
    loading: loadingTempos,
    error: errorTempos,
    data: dataTempos,
  } = useQuery(Tempos);
  const {
    loading: loadingVoices,
    error: errorVoices,
    data: dataVoices,
  } = useQuery(Voices);

  const {
    loading: loadingProducers,
    error: errorProducers,
    data: dataProducers,
  } = useQuery(Producers);




  const handleChangeSong = (e) => {
    setTmpSearch(e.target.value);
  };

  const handlePress = (e) => {
    if (e.key === "Enter") {
      searchSongs();
    }
  };

  const searchSongs = () => {
    if (tmpSearch) {
      setSearch(tmpSearch);
      history.push({
        pathname: "/search",
        state: search,
      });
    }
  };

  const removeElement = () => {
    removeAudio({
      variables: {
        ID: temporalId,
      },
    });
    deleteElementFromList(temporalId);
  };
  const tmpDelete = (id) => {
    settemporalId(id);
  };
  const deleteElementFromList = (id) => {
    setSongs(songs.filter((song) => song.ID !== id));
  };

  const editElement = (id) => {
    const win = window.open(`/song/edit/${id}`, "_blank");
    win.focus();
  };
  const createElement = () => {
    history.push({
      pathname: `/song/create`,
    });
  };

  const updateTable = (total, range) => {
    if (range[0] !== offset) {
      setOffset(range[0]);
    } else {
      setDataLoad(true);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    removeElement();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChangeDate = (val) => {
    setDateFilter(val)

    if (offset == 1) {
      refetch();
    } else {
      setOffset(1);
    }
  }

  const handleChange = (pagination, filters, sorter) => {
    setFilters({
      instruments: filters.AudioInstrumento_ ? filters.AudioInstrumento_ : "",
      themes: filters.AudioAnimo_ ? filters.AudioAnimo_ : "",
      languages: filters.AudioIdioma_ ? filters.AudioIdioma_ : "",
      voices: filters.AudioVoz_ ? filters.AudioVoz_ : "",
      tempos: filters.AudioTempo_ ? filters.AudioTempo_ : "",
      genres: filters.AudioGeneros_ ? filters.AudioGeneros_ : "",
      producers: filters.AudioProductor_ ? filters.AudioProductor_ : "",
    });

    if (offset == 1) {
      refetch();
    } else {
      setOffset(1);
    }
  };

  const onChangeCheckbox = (e) => {
    if (e.target.name == "iswc") {
      setISRC(e.target.checked ? 1 : 0);
    }
    if (e.target.name == "isrc") {
      setISWC(e.target.checked ? 1 : 0);
    }
    setDataLoad(false);
    refetchNumber();
    refetch();
  };

  // const headerColumns = generateHeaders(
  //   headers,
  //   editElement,
  //   tmpDelete,
  //   genres,
  //   mods,
  //   instruments,
  //   languages,
  //   tempos,
  //   voices,
  //   producers,
  //   setFiltersAudio,
  //   filtersAudio,
  //   showModal
  // );

  useEffect(() => {
    if (genres.length > 0 && mods.length > 0 && instruments.length > 0 && languages.length > 0 && tempos.length > 0 && voices.length > 0 && producers.length > 0) {
      const headerColumns = generateHeaders(
        headers,
        editElement,
        tmpDelete,
        genres,
        mods,
        instruments,
        languages,
        tempos,
        voices,
        producers,
        setFiltersAudio,
        filtersAudio,
        showModal
      );
      setHeaderstable(headerColumns)
    }
    // Aqui poner lo de los filtros
  }, [filters, genres, mods, instruments, languages, tempos, voices, producers, filtersAudio, showModal])



  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [offset]);

  




  useEffect(() => {
    if (!loadingMutation && dataMutation) {
      refetch();
    }
  }, [loadingMutation, dataMutation]);

  useEffect(() => {
    if (!loading && !error && data) {
      setSongs(data.AudiosFilter);
      refetchNumber();
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (!loadingNumber && !errorNumber && dataNumber) {
      setTotal(dataNumber.AudiosFilterNumber);
    }
  }, [loadingNumber, dataNumber, errorNumber]);

  useEffect(() => {
    if (!loadingGenres && dataGenres && !errorGenres) {
      setGenres(dataGenres.Genres);
    }
  }, [loadingGenres, dataGenres, errorGenres]);

  useEffect(() => {
    if (!loadingMods && dataMods && !errorMods) {
      setMods(dataMods.Animos);
    }
  }, [loadingMods, dataMods, errorMods]);

  useEffect(() => {
    if (!loadingInstruments && dataInstruments && !errorInstruments) {
      setInstruments(dataInstruments.Instruments);
    }
  }, [loadingInstruments, dataInstruments, errorInstruments]);

  useEffect(() => {
    if (!loadingLanguages && dataLanguages && !errorLanguages) {
      setLanguages(dataLanguages.Languages);
    }
  }, [loadingLanguages, dataLanguages, errorLanguages]);

  useEffect(() => {
    if (!loadingTempos && dataTempos && !errorTempos) {
      setTempos(dataTempos.Tempos);
    }
  }, [loadingTempos, dataTempos, errorTempos]);

  useEffect(() => {
    if (!loadingVoices && dataVoices && !errorVoices) {
      setVoices(dataVoices.Voices);
    }
  }, [loadingVoices, dataVoices, errorVoices]);

  useEffect(() => {
    if (!loadingProducers && dataProducers && !errorProducers) {
      setProducers(dataProducers.Producers);
    }
  }, [loadingProducers, dataProducers, errorProducers]);

  useEffect(() => {
    if (dataLoad && offset) {
      refetch();
    }
  }, [offset, dataLoad, refetch]);



  if (error || errorNumber) return <span>Componente de error</span>;
  if ((loading || loadingNumber) && !dataLoad) return <Loading />;




  return (
    <>
      <h3>Se han encontrado {total} resultados.</h3>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "10px",
          marginRight: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          onClick={createElement}
          type="primary"
          htmlType="submit"
        >
          Add Song
        </Button>
        <div>
          <Checkbox checked={iswc} name="iswc" onChange={onChangeCheckbox}>
            ISWC is empty
          </Checkbox>
          <Checkbox checked={isrc} name="isrc" onChange={onChangeCheckbox}>
            ISRC is empty
          </Checkbox>
        </div>

        <div style={{ paddingRight: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>



        <div style={{ width: 28, color: 'gray', padding: 6 }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 576 512"><path d="M183.6 469.6C177.5 476.2 169 480 160 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L128 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 320c0-17.7 14.3-32 32-32H480c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9L429.3 416H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H352c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9L402.7 352H352c-17.7 0-32-14.3-32-32zM416 32c12.1 0 23.2 6.8 28.6 17.7l64 128 16 32c7.9 15.8 1.5 35-14.3 42.9s-35 1.5-42.9-14.3L460.2 224H371.8l-7.2 14.3c-7.9 15.8-27.1 22.2-42.9 14.3s-22.2-27.1-14.3-42.9l16-32 64-128C392.8 38.8 403.9 32 416 32zM395.8 176h40.4L416 135.6 395.8 176z"/></svg>
        </div>



        
          <Select
            value={dateFilter}
            style={{ width: 180, marginRight: 20 }}
            onChange={handleChangeDate}
            options={[
              {
                value: null,
                label: 'Sin ordenar',
              },
              {
                value: 'new',
                label: 'Más antiguo primero',
              },
              {
                value: 'old',
                label: 'Más reciente primero',
              },
            ]}
          />

          <Input
            style={{ backgroundColor: "#fff", marginRight: 10 }}
            onKeyDown={handlePress}
            name="search"
            onChange={handleChangeSong}
            type="text"
            placeholder="Search songs"
          />
          <SearchOutlined
            onClick={searchSongs}
            style={{ color: "black", fontSize: "25px" }}
          />
        </div>
      </div>
      {
        headerstable.length > 0 && (
          <List
        dataTable={songs}
        total={total}
        updateTable={updateTable}
        loading={loading}
        headerColumns={headerstable}
        handleChange={handleChange}
      />
          
        )

      }
      
      <Modal
        title="Delete Song"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure to delete this song?</p>
      </Modal>

      <div style={{ marginTop: '10px', paddingBottom: '50px', display: 'flex', justifyContent: 'flex-end' }}>
        <button type="button" style={{ opacity: offset>1? 1 : 0.5 }} onClick={() => setOffset(offset - 1) } disabled={offset>1? false : true}>Previous page</button>
        <button type="button" style={{ marginLeft: '10px', opacity: offset<(total/80)? 1 : 0.5 }} onClick={() => setOffset(offset + 1) } disabled={offset<(total/80)? false : true}>Next page</button>
      </div>
    </>
  );
};
