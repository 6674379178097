import { gql } from '@apollo/client';

export default gql`
  {
    LastProjects {
        id
            name
            state
            userId
            createdAt
            updatedAt
            finishedAt
            user {
                Usuario
            }
    }
  }
`;