import gql from "graphql-tag";

export default gql`
  mutation ($idProject: Int!) {
    projectToPlaylist(idProject: $idProject) {
      id
      name
      state
      userId
      createdAt
      updatedAt
      finishedAt
      audiosNumber
      isPlayList
      slug
      user {
        Usuario
      }
    }
  }
`;
