import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client';
import ProjectInfo from "../../GraphQL/queries/getProjectInfo"
import Loading from '../../Components/Loading';
import List from "../../Components/List"
import { generateHeaders } from "../../Services/Songs"
import { Headers } from '../../constants/headers'
import { Typography, Modal } from 'antd';
import "./styles.css"
import RemoveRelationProject from "../../GraphQL/mutations/removeRelationProject"

const { Title } = Typography;

const states = [<span>In progress</span>, <span>Finished</span>, <span>Cancelled</span>]

export default () => {
    const headers = Headers["AudioProject"]
    const {id} = useParams()
    const {loading, data, error, refetch} = useQuery(ProjectInfo, { variables: { id: id } })
    const [songs, setSongs] = useState(null)
    const [visible, setVisible] = useState(false)
    const [idAudio, setIdAudio] = useState(null)
    const [idProject, setIdProject] = useState(null)
    const [project, setProject] = useState(null)
    const [notFound, setNotFound] = useState(false);
    const [removeRelation] = useMutation(RemoveRelationProject, {
        onCompleted() {
            refetch()
        }
    })

    const showModal = (audio, project) => {
        setIdAudio(audio)
        setIdProject(project)
        setVisible(true)
      };

    const handleOk = e => {
        remove()
        setVisible(false)
    };

    const handleCancel = e => {
        setVisible(false)
      };

    const remove = () => {
        removeRelation({variables: {
            idAudio: parseInt(idAudio),
            idProject:idProject
        }})
    }

    useEffect(() => {
        if (!loading && !error && data.ProjectAdmin === null) {
            setNotFound(true)
        } else if (!loading && !error && data.ProjectAdmin) {
            setSongs(data.ProjectAdmin.audios)
            setProject(data.ProjectAdmin)
        }
    }, [loading, data, error])

    if (error) {
        return <span>Componente de error</span>
    }

    if (notFound) {
        return <span>No se ha encontrado</span>
    }
    if (project && songs) {
        const headerColumns = generateHeaders(headers, null, showModal,)
        return (
            <>
                <div className="titulo_project" style={{margin: "15px"}}>
                    <Title level={4}>{project.name}<small> ({project.audiosNumber} songs)</small></Title>
                    <span>Status: <b>{states[project.state]}</b></span>
                </div>
                <Modal
                    title="Delete song from project"
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                >
                    <p>Are you sure you want to remove the song from the project?</p>
                </Modal>
                <List dataTable={songs} headerColumns={headerColumns} />
            </>
        )
    }

    return <Loading />
}