import gql from "graphql-tag";

export default gql`
    mutation($Id: ID!){
        LogViewed(Id: $Id) {
            Idlog
            userlog
            isViewed
        }
    }
`;
