import { gql } from '@apollo/client';

export default gql`
  query GetUser($Id: ID!){
    UserInfo(Id: $Id) {
      Id
      Usuario
      nombre
      email
      descmax
      limitar
      admin
      documento
    }
  }
`;