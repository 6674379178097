import gql from "graphql-tag";

export default gql`
    mutation($ID: ID!){
        removeTheme(IdAnimo: $ID) {
            IdAnimo
            Animo
        }
    }
`;
